import React, { useRef, useState } from "react";
import MainHeader from "../../components/melecules/main-header/main-header";
import CreateCampaign from "../create-campaign/create-campaign";
import TableList from "./table-list/table-list";
const CampaignScreen = ({ editable }) => {
  const Tabs = editable
    ? [
        { id: 1, title: "Campaign" },
        { id: 2, title: "Create" },
      ]
    : [{ id: 1, title: "Campaign" }];
  const [activeTab, setActiveTab] = useState(1);
  const [searchQuery, setSearchQuery] = useState(""); // State to hold search query
  const [selectedData, setSelectedData] = useState(null);

  const listRef = useRef();

  const clickTab = (id, data) => {
    setActiveTab(id);
    setSelectedData(data);
  };

  const onChangeSearch = (e) => {
    setSearchQuery(e.target.value); // Update search query state
  };

  const Tab = {
    1: (
      <TableList
        listRef={listRef}
        searchQuery={searchQuery}
        editData={clickTab}
      />
    ),
    2: <CreateCampaign action={"create"} goBack={clickTab} />,
    3: (
      <CreateCampaign
        action={"edit"}
        goBack={clickTab}
        selectedData={selectedData}
      />
    ),
  };

  const headerTitle = {
    1: "List of Campaign",
    2: "New Campaign",
    3: "Update Campaign",
  };

  return (
    <div>
      <MainHeader
        title={headerTitle[activeTab]}
        searchBar={activeTab === 1}
        button={true}
        onClick={clickTab}
        tab={Tabs}
        onChange={onChangeSearch}
      />
      {Tab[activeTab]}
    </div>
  );
};

export default CampaignScreen;
