import React from "react";

function Button({onClick,children,display}) {
  return (
    <div style={{marginTop: "5%",display:display?"flex":"none"}}>
      <button
        type="submit"
        className="btn btn-dark"
        style={{
          minWidth: "30%",
          margin: "auto",
          display: "block",
        }}
        onClick={onClick}
      >
        {children}
      </button>
    </div>
  );
}

export default Button;
