import { Amplify, Auth } from "aws-amplify";
import jwtDecode from "jwt-decode";
import {
  getCredentials,
  getLocalStorageData,
  setLocalStorage,
} from "../../utilities/helper";
var apigClientFactory = require("aws-api-gateway-client").default;

const getConfigData = () => {
  const { tenant_id, userpool_id, identity_pool_id, client_id, region } =
    getCredentials();

  const amplifyConfig = getLocalStorageData("amplifyConfig");

  const isConfigChanged =
    amplifyConfig &&
    tenant_id &&
    (amplifyConfig.aws_project_region !== region ||
      amplifyConfig.aws_cognito_identity_pool_id !== identity_pool_id ||
      amplifyConfig.aws_user_pools_id !== userpool_id ||
      amplifyConfig.aws_user_pools_web_client_id !== client_id);

  if (
    (userpool_id && identity_pool_id && client_id && region && tenant_id) ||
    isConfigChanged
  ) {
    Amplify.configure({
      aws_project_region: region,
      aws_cognito_identity_pool_id: identity_pool_id,
      aws_cognito_region: region,
      aws_user_pools_id: userpool_id,
      aws_user_pools_web_client_id: client_id,
    });
    setLocalStorage("amplifyConfig", Amplify.configure());
    setLocalStorage("TenantID", tenant_id);
    return {
      aws_project_region: region,
      aws_cognito_identity_pool_id: identity_pool_id,
      aws_cognito_region: region,
      aws_user_pools_id: userpool_id,
      aws_user_pools_web_client_id: client_id,
    };
  } else if (amplifyConfig) {
    return amplifyConfig;
  } else {
    return {
      aws_project_region: process.env.REACT_APP_DEFAULT_AWS_CLI,
      aws_cognito_identity_pool_id:
        process.env.REACT_APP_DEFAULT_IDENTITYPOOL_ID,
      aws_cognito_region: process.env.REACT_APP_DEFAULT_AWS_CLI,
      aws_user_pools_id: process.env.REACT_APP_DEFAULT_USERPOOL_ID,
      aws_user_pools_web_client_id: process.env.REACT_APP_DEFAULT_WEBCLIENT_ID,
    };
  }
};

const getTokens = async () => {
  try {
    const session = await Auth.currentSession();
    const accessToken = session.getAccessToken().getJwtToken();
    const idToken = session.getIdToken().getJwtToken();
    const refreshTokens = session.getRefreshToken().getToken();
    const decodedIdToken = jwtDecode(idToken);
    const region = decodedIdToken.iss.split(".")[1];
    const groups = decodedIdToken["cognito:roles"];
    const userRole = groups ? groups[0] : null;
    const finalRoles = userRole.split("/");
    const userrole = finalRoles[1];
    let exp = decodedIdToken.exp;
    return {
      userRole,
      exp,
      ...{
        accessToken: accessToken,
        idToken: idToken,
        userrole: userrole,
        user: decodedIdToken?.email,
        refreshTokens: refreshTokens,
        region: region,
        error: false,
      },
    };
  } catch (error) {
    return { error };
  }
};

const getTokenCredentials = async () => {
  try {
    console.log("[AUTH] Getting AWS IAM credentials...");

    // Force bypass cache to ensure fresh credentials
    const credentials = await Auth.currentCredentials({ bypassCache: true });

    const accessKeyId = credentials.accessKeyId;
    const secretAccessKey = credentials.secretAccessKey;
    const sessionToken = credentials.sessionToken;

    // Get the actual expiration of IAM credentials (this is separate from Cognito token expiration)
    const expiresAt = credentials.expiration
      ? credentials.expiration.getTime()
      : null;

    if (expiresAt) {
      const currentTime = new Date().getTime();
      const timeRemainingMs = expiresAt - currentTime;
      const minutesRemaining = Math.floor(timeRemainingMs / (1000 * 60));

      console.log(
        `[AUTH] IAM credentials obtained. Expires at: ${new Date(
          expiresAt
        ).toLocaleString()}`
      );
      console.log(
        `[AUTH] IAM credentials time remaining: ${minutesRemaining} minutes`
      );
    } else {
      console.warn(
        "[AUTH] Could not determine IAM credentials expiration time"
      );
    }

    return {
      accessKeyId,
      secretAccessKey,
      sessionToken,
      expiresAt,
    };
  } catch (error) {
    console.error("Error retrieving IAM credentials:", error);
    return null;
  }
};

const refreshToken = async () => {
  try {
    console.log("[AUTH] Refreshing tokens...");
    // Force refresh the current session
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();

    if (!cognitoUser) {
      console.error("[AUTH] No authenticated user found during refresh");
      return null;
    }

    // Get the current refresh token
    const refreshToken = currentSession.getRefreshToken();

    // Explicitly refresh the session
    const newSession = await new Promise((resolve, reject) => {
      cognitoUser.refreshSession(refreshToken, (err, session) => {
        if (err) {
          console.error("[AUTH] Error refreshing session:", err);
          reject(err);
        } else {
          resolve(session);
        }
      });
    });

    // Get the refreshed tokens
    const accessToken = newSession.getAccessToken().getJwtToken();
    const idToken = newSession.getIdToken().getJwtToken();
    const decodedIdToken = jwtDecode(idToken);
    const region = decodedIdToken.iss.split(".")[1];

    // Extract user roles from the decoded token
    const groups = decodedIdToken["cognito:roles"];
    const userRole = groups ? groups[0] : null;
    const finalRoles = userRole ? userRole.split("/") : [];
    const userrole = finalRoles.length > 1 ? finalRoles[1] : "user";

    // Log expiration time for debugging
    const exp = decodedIdToken.exp;
    const expirationDate = new Date(exp * 1000);
    console.log(
      `[AUTH] Token refresh successful. New expiration: ${expirationDate.toLocaleString()}`
    );

    return {
      userrole,
      accessToken,
      idToken,
      region,
      exp,
    };
  } catch (error) {
    console.error("[AUTH] Error refreshing token:", error);

    // If this is an authentication error, initiate sign-out
    if (error.name === "NotAuthorizedException") {
      console.warn("[AUTH] Authentication expired completely, signing out...");
      try {
        await Auth.signOut();
      } catch (signOutError) {
        console.error("[AUTH] Error during sign out:", signOutError);
      }
    }

    return null;
  }
};

const configApiClient = (config) => {
  const apiClient = apigClientFactory.newClient(config);
  return apiClient;
};

export {
  configApiClient,
  getConfigData,
  getTokenCredentials,
  getTokens,
  refreshToken,
};
