import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { primary } from "../../../constants/color";
import AutorenewIcon from "@mui/icons-material/Autorenew";

import "./DomainInput.css"; // Import CSS file for styling
import useAwsApiRequest from "../../../hook/useAwsApiRequest";
import { useSelector } from "react-redux";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// Constants
const SPECIAL_CHARS_REGEX = /[^a-zA-Z0-9-]/;
const START_END_HYPHEN_ERROR =
  "Domain name should not contain hyphens at the start or end";
const SPECIAL_CHARS_ERROR = "Domain name should not contain special characters";
const EMPTY_FIELD_ERROR = "Domain name should not be empty";
const PLACEHOLDER_TEXT = "Enter Domain Name";
const DEFAULT_DOMAIN = "https://embfota-";
const DOMAIN_SUFFIX = ".auth.ap.south-1.amazoncognito.com";

const DomainInput = ({ updateDomain }, ref) => {
  let tenantId = JSON.parse(localStorage.getItem("TenantID"));

  const [domainName, setDomainName] = useState("");
  const [error, setError] = useState("");
  const [isRotating, setIsRotating] = useState(false);
  const [isAvailable, setIsAvailable] = useState(false);

  const { statusCode, loading, data, apiRequest } = useAwsApiRequest();
  const cred = useSelector((state) => state.cred.cred);
  const tokens = useSelector((state) => state.tokens.tokens);

  useImperativeHandle(ref, () => ({
    validate: () => validateDomainName(),
  }));

  useEffect(() => {
    if (statusCode == 500) {
      setError("Domain Name Is Not Available");
      stopRotate();
    } else if (statusCode == "400") {
      stopRotate();
      setError("Domain Name Is Not Available");
    }
  }, [statusCode]);

  // Validation Functions
  const validateDomainName = () => {
    setError("");
    setIsRotating(true); // Start rotation
    if (!domainName.trim()) {
      setError(EMPTY_FIELD_ERROR);
      stopRotate();
    } else if (hasSpecialCharacters()) {
      setError(SPECIAL_CHARS_ERROR);
      stopRotate();
    } else if (startsWithOrEndsWithHyphen()) {
      setError(START_END_HYPHEN_ERROR);
      stopRotate();
    } else {
      checkAvailability();
      updateDomain(domainName);
      return domainName;
      // Proceed with availability check
    }
  };

  const stopRotate = () => {
    setTimeout(() => {
      setIsRotating(false);
    }, 500);
  };

  const hasSpecialCharacters = () => SPECIAL_CHARS_REGEX.test(domainName);

  const startsWithOrEndsWithHyphen = () =>
    domainName.startsWith("-") || domainName.endsWith("-");

  const handleDomainNameChange = (event) => {
    setIsAvailable(false);
    setDomainName(event.target.value);
  };

  const checkAvailability = async () => {
    let data = {
      pathParam: {},
      endpoint: `http://localhost:8005/cognitoservice/userpooldomain/${domainName}`,
      method: "GET",
      headerCred: {
        "tenant-id": tenantId||process.env.REACT_APP_USER_ROLE,
        "cli-region": process.env.REACT_APP_DEFAULT_REGION,
        "aws-secret": cred?.secretAccessKey,
        "aws-key": cred?.accessKeyId,
        "aws-session": cred?.sessionToken,
      },
      body: {},
    };
    const response = await apiRequest(data);
    if (response) {
      
      if (response?.userPool&&Object.keys(response?.userPool?.DomainDescription).length === 0) {
        setIsAvailable(true);
        stopRotate();
      } else {
        setIsAvailable(false);
        stopRotate();
        setError(response?.message||"Domain is already exist");
      }
    }
  };

  return (
    <div className="domain-input-container">
      <label className="domain-label">Domain *</label>
      <div className="domain-input-wrapper">
        <span>{DEFAULT_DOMAIN}</span>
        <input
          placeholder={PLACEHOLDER_TEXT}
          className="domain-input"
          value={domainName}
          onChange={handleDomainNameChange}
        />
        <span>{DOMAIN_SUFFIX}</span>
        <div className="check-availability" onClick={validateDomainName}>
          {isAvailable ? (
            <CheckCircleIcon
              className={`renew-icon`}
              style={{ color: "green" }}
            />
          ) : (
            <div className="check-button">
              Check Available
              <AutorenewIcon
                className={`renew-icon ${isRotating ? "rotate" : ""}`}
                style={{fontSize:16,color:"white",marginLeft:5}}
              />
            </div>
          )}
          {isAvailable && "Available"}
        </div>
      </div>
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default forwardRef(DomainInput);
