import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import MainHeader from "../../components/melecules/main-header/main-header";
import CreateFleet from "../create-fleet/create-fleet";
import TableList from "./table-list/table-list";

const FleetScreen = ({ editable }) => {
  const navigate = useNavigate();
  const Tabs = editable
    ? [
        { id: 1, title: "Fleet" },
        { id: 2, title: "Create Fleet" },
      ]
    : [{ id: 1, title: "Fleet" }];
  const [activeTab, setActiveTab] = useState(1);
  const listRef = useRef();
  const [searchQuery, setSearchQuery] = useState(""); // State to hold search query
  const [selectedData, setSelectedData] = useState(null);

  const clickTab = (id, data) => {
    setActiveTab(id);
    setSelectedData(data);
  };

  const Tab = {
    1: (
      <TableList
        listRef={listRef}
        searchQuery={searchQuery}
        editData={clickTab}
      />
    ), // Pass searchQuery as prop
    2: <CreateFleet action={"create"} />,
    3: (
      <CreateFleet
        action={"edit"}
        goBack={clickTab}
        selectedData={selectedData}
      />
    ),
  };

  const headerTitle = {
    1: "List of Available Fleet",
    2: "Create Fleet",
    3: "Update Fleet",
  };

  const onChangeSearch = (e) => {
    setSearchQuery(e.target.value); // Update search query state
  };
  return (
    <div>
      <MainHeader
        title={headerTitle[activeTab]}
        searchBar={activeTab === 1}
        button={true}
        onClick={clickTab}
        tab={Tabs}
        onChange={onChangeSearch}
      />
      {Tab[activeTab]}
    </div>
  );
};

export default FleetScreen;
