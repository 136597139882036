import { Button } from "@mui/material";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Lottie from "react-lottie";
import ReactPaginate from "react-paginate";
import indicator from "../../../assets/lottie/loader.json";
import {
  ArrowBackIosNewOutlinedIcon,
  ArrowForwardIosOutlinedIcon,
} from "../../../constants/icons";
import useAwsApiRequest from "../../../hook/useAwsApiRequest";
import useStatusStore from "../../../services/zustand/status-popup";
import "./VinTable.css";

const VinTable = (
  {
    tgu,
    startDate,
    endDate,
    startRange,
    endRange,
    modelName,
    firmwareVersion,
    addVinDetails,
    debugTest = false, // Add debugTest flag to control dummy data
  },
  ref
) => {
  const [fadeIn, setFadeIn] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [vinList, setVinList] = useState([]);
  const [noData, setNoData] = useState(false);
  const [loader, setLoader] = useState(false);
  const [disableButton, setDisable] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(true);
  const [isSelectUi, setIsSelectUi] = useState(true);

  const [vinCount, setVinCount] = useState(0);
  const [selectedVinCount, setSelectedVinCount] = useState(0);
  const [selectedVinIds, setSelectedVinIds] = useState(new Set());
  const [unselectedVinIds, setUnselectedVinIds] = useState(new Set());
  const [allVins, setAllVins] = useState(new Set()); // Track all VINs seen across pages

  const selectAllRef = useRef(true);

  const { statusCode, loading, data, error, apiRequest } = useAwsApiRequest();
  const setMessage = useStatusStore((state) => state?.setMessage);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: indicator,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    setFadeIn(true);
  }, []);

  useEffect(() => {
    if (error) {
      setMessage(statusCode, error, "error");
    }
  }, [error, statusCode]);

  // Fix the button disable logic
  useEffect(() => {
    // Only disable button when there are no VINs selected at all
    // For "Select All" mode, this means all VINs are unselected
    // For manual selection mode, this means no VINs are selected
    let shouldDisable = false;

    if (loader) {
      shouldDisable = true;
    } else if (isSelectAll) {
      // In "Select All" mode, disable if all VINs are unselected
      shouldDisable = unselectedVinIds.size >= vinCount;
    } else {
      // In manual selection mode, disable if no VINs are selected
      shouldDisable = selectedVinIds.size === 0;
    }

    setDisable(shouldDisable);
  }, [isSelectAll, selectedVinIds, unselectedVinIds, vinCount, loader]);

  // Update the UI select checkbox when unselected or selected changes
  useEffect(() => {
    if (vinCount > 0) {
      let isAllSelected;

      if (isSelectAll) {
        // In "Select All" mode, all are selected if unselected is empty
        isAllSelected = unselectedVinIds.size === 0;
      } else {
        // In manual selection mode, check if all VINs are selected
        isAllSelected =
          selectedVinIds.size === allVins.size && allVins.size > 0;

        // If user has manually selected all VINs, switch to "Select All" mode
        if (isAllSelected && selectedVinIds.size > 0 && !selectAllRef.current) {
          setIsSelectAll(true);
          selectAllRef.current = true;
          setUnselectedVinIds(new Set());
        }
      }

      setIsSelectUi(isAllSelected);
      updateSelectedCount();
    }
  }, [unselectedVinIds, selectedVinIds, allVins, vinCount, isSelectAll]);

  // Update selected count based on selection state
  const updateSelectedCount = () => {
    if (isSelectAll) {
      setSelectedVinCount(vinCount - unselectedVinIds.size);
    } else {
      setSelectedVinCount(selectedVinIds.size);
    }
  };

  useImperativeHandle(ref, () => ({
    loadData: () => {
      getData(page);
    },
  }));

  const getData = async (p) => {
    setDisable(true);
    setNoData(false);
    setLoader(true);

    let response;
    if (debugTest) {
      // Use dummy data if debugTest is true
      if (p == 1) {
        response = {
          data: [
            { vin: "VIN0001", isSelected: true },
            { vin: "VIN0001", isSelected: true },
            { vin: "VIN0001", isSelected: true },
            { vin: "VIN0004", isSelected: true },
            { vin: "VIN0005", isSelected: true },
            { vin: "VIN0006", isSelected: true },
            { vin: "VIN0007", isSelected: true },
            { vin: "VIN0008", isSelected: true },
            { vin: "VIN0009", isSelected: true },
            { vin: "VIN00010", isSelected: true },
            // Add more dummy data as needed
          ],
          totalPages: 3,
          totalItems: 30,
        };
      } else if (p == 2) {
        response = {
          data: [
            { vin: "VIN00011", isSelected: true },
            { vin: "VIN00012", isSelected: true },
            { vin: "VIN00013", isSelected: true },
            { vin: "VIN00014", isSelected: true },
            { vin: "VIN00015", isSelected: true },
            { vin: "VIN00016", isSelected: true },
            { vin: "VIN00017", isSelected: true },
            { vin: "VIN00018", isSelected: true },
            { vin: "VIN00019", isSelected: true },
            { vin: "VIN00020", isSelected: true },
            // Add more dummy data as needed
          ],
          totalPages: 3,
          totalItems: 30,
        };
      } else {
        response = {
          data: [
            { vin: "VIN00021", isSelected: true },
            { vin: "VIN00022", isSelected: true },
            { vin: "VIN00023", isSelected: true },
            { vin: "VIN00024", isSelected: true },
            { vin: "VIN00025", isSelected: true },
            { vin: "VIN00026", isSelected: true },
            { vin: "VIN00027", isSelected: true },
            { vin: "VIN00028", isSelected: true },
            { vin: "VIN00029", isSelected: true },
            { vin: "VIN00030", isSelected: true },
            // Add more dummy data as needed
          ],
          totalPages: 3,
          totalItems: 30,
        };
      }
    } else {
      const queryParamsRange = {
        modelName,
        firmwareVersion,
        startVin: startRange,
        endVin: endRange,
        numChars: 5,
        page: p,
        limit,
      };

      const queryParamsDate = {
        modelName,
        firmwareVersion,
        startDate: startDate,
        endDate: endDate,
        numChars: 5,
        page: p,
        limit,
      };

      let data = {
        pathParam: {},
        endpoint:
          "http://localhost:8001/vehicleservice/vehicle/vehiclefilter/filter",
        method: "GET",
        body: {},
        queryParams: startDate ? queryParamsDate : queryParamsRange,
      };

      response = await apiRequest(data);
    }

    if (response?.data?.length) {
      // Set total count on first load or when it changes
      if (vinCount !== response.totalItems) {
        setVinCount(response.totalItems);
        // Initialize selected count properly on first load
        if (selectAllRef.current) {
          setSelectedVinCount(response.totalItems);
        }
      }

      // Track all VINs seen across pages
      const newAllVins = new Set([...allVins]);
      response.data.forEach((item) => {
        newAllVins.add(item.vin);
      });
      setAllVins(newAllVins);

      // Determine selection state for each VIN
      const updatedData = response.data.map((item) => {
        let isItemSelected = false;

        if (isSelectAll) {
          // If "Select All" is active, item is selected unless explicitly unselected
          isItemSelected = !unselectedVinIds.has(item.vin);
        } else {
          // If "Select All" is not active, item is selected only if explicitly selected
          isItemSelected = selectedVinIds.has(item.vin);
        }

        return {
          ...item,
          isSelected: isItemSelected,
        };
      });

      setVinList(updatedData);
      setPageCount(response?.totalPages);
      updateSelectedCount();
    } else {
      setNoData(true);
    }

    setLoader(false);
  };

  const handlePageClick = (page) => {
    setPage(page.selected);
    getData(page.selected + 1);
  };

  const selectAll = (event) => {
    const value = event.target.checked;
    setIsSelectAll(value);
    setIsSelectUi(value);
    selectAllRef.current = value;

    if (value) {
      // Selecting all VINs - clear unselected list
      setUnselectedVinIds(new Set());
      setSelectedVinCount(vinCount);
    } else {
      // Unselecting all VINs - clear selected list and add current page VINs to selectedVinIds
      setSelectedVinIds(new Set());
      // Add all seen VINs to unselectedVinIds
      setUnselectedVinIds(new Set([...allVins]));
      setSelectedVinCount(0);
    }

    // Update current page UI
    const updatedData = vinList.map((item) => ({
      ...item,
      isSelected: value,
    }));

    setVinList(updatedData);
  };

  const handleCheckboxChange = (index) => {
    const updatedData = [...vinList];
    updatedData[index].isSelected = !updatedData[index].isSelected;

    const vin = updatedData[index].vin;

    if (isSelectAll) {
      // In "Select All" mode, we manage unselected VINs
      const unselectedVins = new Set([...unselectedVinIds]);

      if (updatedData[index].isSelected) {
        unselectedVins.delete(vin);
      } else {
        unselectedVins.add(vin);
      }

      setUnselectedVinIds(unselectedVins);
    } else {
      // In manual selection mode, we manage selected VINs
      const selectedVins = new Set([...selectedVinIds]);

      if (updatedData[index].isSelected) {
        selectedVins.add(vin);
      } else {
        selectedVins.delete(vin);
      }

      setSelectedVinIds(selectedVins);

      // Check if all VINs are now selected
      if (selectedVins.size === allVins.size && allVins.size > 0) {
        // If all VINs are selected manually, switch to "Select All" mode
        setIsSelectAll(true);
        selectAllRef.current = true;
        setUnselectedVinIds(new Set());
      }
    }

    setVinList(updatedData);
    updateSelectedCount();
  };

  const addSelectedVin = () => {
    if (isSelectAll) {
      // Pass all unselected VINs to parent
      addVinDetails(true, Array.from(unselectedVinIds), [], selectedVinCount);
    } else {
      // Pass specific selected VINs to parent
      // Create objects consistent with expected format in parent
      const selectedVinObjects = Array.from(selectedVinIds).map((vin) => ({
        vin,
      }));
      addVinDetails(false, [], selectedVinObjects, selectedVinCount);
    }
  };

  const rows = [];

  for (let i = 0; i < vinList.length; i += 2) {
    rows.push(vinList.slice(i, i + 2));
  }

  return (
    <div className={`container-vin ${fadeIn ? "fade-in" : ""}`}>
      <div className="header-vin">
        <div />
        <strong>VIN Count:{selectedVinCount}</strong>
        <div style={{ width: "90px" }}>
          <div className="vin-item">
            Select All
            <input type="checkbox" onChange={selectAll} checked={isSelectUi} />
          </div>
        </div>
        <div style={{}}>
          <Button
            onClick={addSelectedVin}
            variant="contained"
            sx={{
              backgroundColor: "#4822B4",
            }}
            disabled={disableButton}
          >
            Select
          </Button>
        </div>
      </div>
      <div className="vin-list">
        {loader ? (
          <Lottie options={defaultOptions} height={25} width={25} />
        ) : (
          <>
            {noData ? (
              "No Data Found"
            ) : (
              <>
                {rows.map((row, rowIndex) => (
                  <div key={rowIndex} className="vin-row">
                    {row.map((vin, vinIndex) => (
                      <div key={vinIndex} className="vin-item">
                        {vin.vin}
                        <input
                          type="checkbox"
                          className="vin-check"
                          checked={vin.isSelected}
                          onChange={() =>
                            handleCheckboxChange(rowIndex * 2 + vinIndex)
                          }
                        />
                      </div>
                    ))}
                  </div>
                ))}
              </>
            )}
          </>
        )}
      </div>
      <div className="vin-page">
        <ReactPaginate
          previousLabel={
            <ArrowBackIosNewOutlinedIcon
              style={{ fontSize: 14, fontWeight: 600 }}
            />
          }
          nextLabel={
            <ArrowForwardIosOutlinedIcon
              style={{ fontSize: 14, fontWeight: 600 }}
            />
          }
          breakLabel={"...."}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttns"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
      </div>
    </div>
  );
};

export default forwardRef(VinTable);
