import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import MainHeader from "../../components/melecules/main-header/main-header";
import CreateVehicleModel from "../create-vehicle-model/create-vehicle-model";
import TableList from "./table-list/table-list";

function VehicleModel({ editable }) {
  const navigate = useNavigate();
  const Tabs = editable
    ? [
        { id: 1, title: "Vehicle Models" },
        { id: 2, title: "Create Model" },
      ]
    : [{ id: 1, title: "Vehicle Models" }];

  const [activeTab, setActiveTab] = useState(1);
  const listRef = useRef();
  const [searchQuery, setSearchQuery] = useState(""); // State to hold search query
  const [selectedData, setSelectedData] = useState(null);

  const clickTab = (id, data) => {
    setActiveTab(id);
    setSelectedData(data);
  };

  const Tab = {
    1: (
      <TableList
        editable={editable}
        listRef={listRef}
        searchQuery={searchQuery}
        editData={clickTab}
      />
    ), // Pass searchQuery as prop
    2: <CreateVehicleModel action={"create"} />,
    3: (
      <CreateVehicleModel
        action={"edit"}
        goBack={clickTab}
        selectedData={selectedData}
      />
    ),
  };

  const headerTitle = {
    1: "List of Available Vehicle Models",
    2: "Create Vehicle Model",
    3: "Update Vehicle Model",
  };

  const onChangeSearch = (e) => {
    setSearchQuery(e.target.value); // Update search query state
  };

  return (
    <div>
      <MainHeader
        title={headerTitle[activeTab]}
        searchBar={activeTab === 1}
        button={true}
        onClick={clickTab}
        tab={Tabs}
        onChange={onChangeSearch}
      />
      {Tab[activeTab]}
    </div>
  );
}

export default VehicleModel;
