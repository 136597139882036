import { animated, useSpring } from "@react-spring/web";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import FormContainer from "../../../components/organizer/form-container/form-container";
import {
  ArrowBackIosNewOutlinedIcon,
  ArrowForwardIosOutlinedIcon,
} from "../../../constants/icons";
import useAwsApiRequest from "../../../hook/useAwsApiRequest";
import RecordList from "./record-list";
import SingleList from "./single-list";
import "./uploadHistory.css";

const UploadHistory = (props, ref) => {
  const { loading, apiRequest } = useAwsApiRequest();
  const [history, setHistory] = useState([]);
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [recordType, setRecordType] = useState(null);
  const [openRecordList, setOpenRecordList] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFilterType, setSelectedFilterType] = useState("Last 7 Days");
  const [newDate, setDate] = useState(null);
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);

  const users = useSelector((state) => state.users.users);

  const width = useSelector((state) => state.width.width);
  const filterTypes = ["Last 7 Days", "By Date"];

  useEffect(() => {
    if (
      selectedFilterType == "Last 7 Days" ||
      (selectedFilterType == "By Date" && newDate)
    ) {
      getData(page);
    }
  }, [newDate, selectedFilterType]);

  const popUpAnimation = useSpring({
    transform: isOpen ? `scale(1)` : `scale(0)`,
    opacity: isOpen ? 1 : 0,
    config: { tension: 220, friction: 20 },
  });
  console.log("users", users);
  useImperativeHandle(ref, () => ({
    loadData: () => {
      getData(1);
    },
  }));

  const getData = async (page) => {
    // const groupedData = groupDataByFilename(dummyData);
    // setHistory(groupedData);
    // return;
    let data = {
      pathParam: {},
      endpoint: "http://localhost:8001/vehicleservice/vehicle/uploaddetails",
      method: "GET",
      body: {},
      queryParams: {
        user_name: users,
        page,
        limit: 10,
      },
      // defaultTen: "9e1be8c7-10cf-40b7-ae8e-3ada6df26cc0",
    };

    if (newDate) {
      data.queryParams.date = newDate;
    }

    const response = await apiRequest(data);
    if (response) {
      setHistory(response?.data);
      setPageCount(response?.pagination?.total / 10);
    }
  };

  const updateStatus = (filename) => {
    setHistory((prevData) =>
      prevData.map((item) =>
        item.filename === filename ? { ...item, isSubmitted: true } : item
      )
    );
  };

  const showRecords = (type, data) => {
    console.log(type);
    setSelectedRecords(data);
    setRecordType(type);
    setOpenRecordList(true);
  };

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const selectFilter = (type) => {
    setSelectedFilterType(type);
    setIsOpen(false);
    if (type !== "By Date") {
      setDate(null);
    } else {
      // const date = new Date();
      // const formattedDate = date.toISOString().split('T')[0]; // Extract only the date part (YYYY-MM-DD)
      // setDate(formattedDate);
    }
  };
  const filterComponent = () => {
    // Format the newDate for the input
    const formattedDate = newDate
      ? newDate.toISOString().split("T")[0] // Formats to 'YYYY-MM-DD'
      : "";

    return (
      <div className="filter-container" style={{ flexDirection: "row" }}>
        <div className="filter-button" onClick={togglePopup}>
          {selectedFilterType}
        </div>
        {selectedFilterType == "By Date" ? <div style={{ width: 5 }} /> : null}
        {selectedFilterType == "By Date" ? (
          <div className="filter-button" style={{ padding: "2px" }}>
            <input
              type="date"
              style={{ border: "none", borderRadius: 2 }}
              value={formattedDate} // Use formatted date string
              onChange={(e) => setDate(new Date(e.target.value))} // Convert back to Date object
              className="date-picker-input"
            />
          </div>
        ) : null}

        <animated.div
          style={{
            ...popUpAnimation,
            position: "absolute",
            background: "#fff",
            border: "1px solid #ccc",
            borderRadius: "5px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            zIndex: 1000,
          }}
        >
          {filterTypes.map((type) => (
            <div
              key={type}
              className="filter-button"
              style={{
                cursor: "pointer",
                border: "1px solid #eee",
                backgroundColor: "white",
                margin: "5px",
                height: "30px",
                color: "black",
                fontFamily: "Rubik",
              }}
              onClick={() => {
                selectFilter(type);
              }}
            >
              {type}
            </div>
          ))}
        </animated.div>
      </div>
    );
  };

  const handlePageClick = (page) => {
    setPage(page.selected);
    getData(page.selected + 1);
  };

  const boxWidth = (window.innerWidth / 1.4 - width) / 6;
  const boxWidthFilename = (window.innerWidth / 1.4 - width) / 5;

  return (
    <div style={{ marginLeft: "10px" }}>
      <FormContainer
        minusWidth={width}
        header={true}
        title={"History"}
        divWidth={1.33}
        CustomComponent={filterComponent}
      >
        <div
          className="list-container"
          style={{
            justifyContent: "start",
            // loading || history?.length == 0 ? "center" : "start",
            // overflow: "hidden",
          }}
        >
          {loading ? (
            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: 14,
                fontFamily: "Rubik",
              }}
            >
              <div className="spinner"></div>
            </div>
          ) : (
            <>
              <div
                className="single-container"
                style={{ backgroundColor: "#A6B8DC" }}
              >
                <div className="slBox" style={{ width: boxWidth / 3 }}>
                  Sl.No
                </div>
                <div className="slBox" style={{ width: boxWidthFilename }}>
                  File Name
                </div>
                <div className="slBox" style={{ width: boxWidth }}>
                  Total Records
                </div>
                <div className="slBox" style={{ width: boxWidth }}>
                  Invalid Records
                </div>
                <div className="slBox" style={{ width: boxWidth / 1.5 }}>
                  Pending for submission
                </div>
                <div className="slBox" style={{ width: boxWidth }}>
                  Submitted Records
                </div>
                <div className="slBox" style={{ width: boxWidth }}>
                  Date
                </div>
              </div>
              {history?.length ? (
                history.map((x, index) => (
                  <SingleList
                    key={x?.filename}
                    item={x}
                    updateStatus={updateStatus}
                    index={index}
                    boxWidth={boxWidth}
                    boxWidthFilename={boxWidthFilename}
                    showRecords={showRecords}
                  />
                ))
              ) : (
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: 14,
                    fontFamily: "Rubik",
                  }}
                >
                  No Data Found
                </div>
              )}
            </>
          )}
        </div>
        <div className="pagination-box" style={{ backgroundColor: "white" }}>
          <ReactPaginate
            previousLabel={
              <ArrowBackIosNewOutlinedIcon
                style={{ fontSize: 14, fontWeight: 600 }}
              />
            }
            nextLabel={
              <ArrowForwardIosOutlinedIcon
                style={{ fontSize: 14, fontWeight: 600 }}
              />
            }
            breakLabel={"...."}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttns"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          />
        </div>
      </FormContainer>
      {openRecordList && (
        <RecordList
          openRecordList={openRecordList}
          onClose={() => setOpenRecordList(false)}
          records={selectedRecords}
          type={recordType}
        />
      )}
    </div>
  );
};

export default forwardRef(UploadHistory);
