import React, { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import { getLocalStorageData } from "../../../utilities/helper";

import Paper from "@mui/material/Paper";
import { ToastContainer, toast } from "react-toastify";
import DeleteModel from "../../../components/melecules/delete-model/delete-model";
import CommonTable from "../../../components/organizer/common-table/common-table";
import ErrorHandler from "../../../components/organizer/erro-handler/error-handler";
import {
  ArrowBackIosNewOutlinedIcon,
  ArrowForwardIosOutlinedIcon,
} from "../../../constants/icons";
import useAwsApiRequest from "../../../hook/useAwsApiRequest";
import { FormatNumber } from "../../../utilities/numberFormat";

function TableList({ searchQuery, editData }) {
  const { statusCode, loading, data, apiRequest } = useAwsApiRequest();
  const tenant = getLocalStorageData("TenantID");

  const responseData = useRef();
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(0); // Current page number
  const [totalPage, setTotalPage] = useState(1);
  const [filteredData, setFilteredData] = useState([]); // State variable to hold filtered data
  const [tableHeader, setTableHeader] = useState([
    { id: 1, name: "Sl.No" },
    { id: 2, name: "VIN" },
    { id: 3, name: "Model Name" },
    { id: 4, name: "Fleet Name" },
    { id: 5, name: "Firmware Version" },
    { id: 6, name: "Vehicle Status" },
    { id: 7, name: "Region" },
    { id: 8, name: "Action" },
    // { id: 9, name: "Created At" },
    // { id: 10, name: "Updated At" },
  ]);

  const [deleteModel, setDeleteModel] = useState(false);

  const selectedData = useRef(null);

  useEffect(() => {
    getFleet(page);
  }, []);

  useEffect(() => {
    if (searchQuery !== undefined && searchQuery !== null) {
      setPage(0);
      getFleet(1);
    }
  }, [searchQuery]);

  const getFleet = async (page) => {
    let data = {
      pathParam: {},
      endpoint: `http://localhost:8080/vehicleservice/vehicle`,
      method: "GET",
      body: {},
      queryParams: {
        page,
        limit: 10,
        search: searchQuery,
      },
    };

    const response = await apiRequest(data);
    if (response?.data?.length) {
      responseData.current = response.data;
      const filteredData = filterFields(response.data, true);
      setTableData(filteredData);
      setFilteredData(filteredData);
      // const dataCount = filteredData.length;
      // const itemsPerPage = 10;
      // const totalPages = Math.ceil(dataCount / itemsPerPage);
      setTotalPage(response?.pagination?.total / 10);
    }
  };

  const filterFields = (data, hide) => {
    return data.map((item, index) => {
      const {
        created_at,
        firmware_version,
        fleet_name,
        model_name,
        onboarded_status,
        region,
        tenant_id,
        thing_name,
        updated_at,
        version,
        vin,
        vin_id,
      } = item; // Extract necessary properties

      if (hide) {
        return {
          id: index + 1,
          vin,
          model_name,
          fleet_name,
          firmware_version: firmware_version
            ? FormatNumber(firmware_version)
            : "Not Found",
          onboarded_status: onboarded_status === "P" ? "Pending" : "Success",
          region,
          action: "delete",
          // created_at: moment(created_at).format("MMMM Do YYYY, h:mm a"),
          // updated_at: moment(updated_at).format("MMMM Do YYYY, h:mm a"),
        };
      } else {
        return {
          id: index + 1,
          vin,
          model_name,
          fleet_name,
          firmware_version: firmware_version
            ? FormatNumber(firmware_version)
            : "Processing",
          onboarded_status,
          region,
          action: "action",
          // created_at: moment(created_at).format("MMMM Do YYYY, h:mm a"),
          // updated_at: moment(updated_at).format("MMMM Do YYYY, h:mm a"),
        };
      }
    });
  };

  const action = (a, data) => {
    if (a == "edit") {
      editData(
        3,
        responseData.current.find((x) => x.vin == data.vin)
      );
    } else {
      setDeleteModel(true);
      selectedData.current = responseData.current.find(
        (x) => x.vin == data.vin
      );
    }
  };

  const showMoreData = (show) => {
    const filteredData = filterFields(responseData.current, show);
    setTableData(filteredData);
    if (show) {
      // If hide is true, remove the last 2 objects from the tableHeader array
      setTableHeader((prevHeader) =>
        prevHeader.slice(0, prevHeader.length - 1)
      );
    } else {
      // If hide is false or undefined, reset the tableHeader array to its original state
      setTableHeader([
        { id: 1, name: "Sl.No" },
        { id: 2, name: "Fleet ID" },
        { id: 3, name: "Fleet Name" },
        { id: 7, name: "Action" },
        { id: 5, name: "Description" },
        { id: 6, name: "Created At" },
      ]);
    }
  };

  const handlePageClick = (page) => {
    setPage(page.selected);
    getFleet(page.selected + 1);
  };

  const close = () => {
    setDeleteModel(false);
  };

  const modelDelete = async () => {
    let data = {
      pathParam: {},
      endpoint: `http://localhost:8080/vehicleservice/vehicle/${selectedData.current?.vin}`,
      method: "DELETE",
      body: {},
    };

    const response = await apiRequest(data);
    if (response?.status == 204) {
      toast.success("Successfully Deleted Fleet", {
        position: "top-right",
      });
      close();
      getFleet(page);
    } else {
      toast.error("Network Error", {
        position: "top-right",
      });
      close();
    }
  };

  const itemsPerPage = 10;
  const offset = currentPage * itemsPerPage; // Calculate offset for pagination
  const currentPageData = filteredData.slice(offset, offset + itemsPerPage);

  return (
    <ErrorHandler statusCode={statusCode} loading={loading}>
      <Paper sx={{ width: "100%", overflow: "hidden", marginTop: "2%" }}>
        <CommonTable
          tableHeader={tableHeader}
          data={tableData}
          onClick={action}
          showMoreData={showMoreData}
          showMoreIcon={false}
        />
        <br></br>

        <div
          style={{
            border: "1px solid #d3d3d3",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-evenly",
            padding: "5px",
            paddingTop: "15px",
          }}
        >
          <p
            style={{
              fontWeight: 600,
              color: "gray",
              fontSize: 13,
              fontFamily: "Rubik",
            }}
          >
            {/* Showing {offset + 1} to {offset + currentPageData.length} of{" "}
            {filteredData.length} entries */}
          </p>
          <div style={{ marginTop: 2 }}>
            <ReactPaginate
              previousLabel={
                <ArrowBackIosNewOutlinedIcon
                  style={{ fontSize: 14, fontWeight: 600 }}
                />
              }
              nextLabel={
                <ArrowForwardIosOutlinedIcon
                  style={{ fontSize: 14, fontWeight: 600 }}
                />
              }
              breakLabel={"...."}
              marginPagesDisplayed={2}
              pageCount={totalPage}
              forcePage={page}
              onPageChange={handlePageClick}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttns"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
        </div>
      </Paper>
      {deleteModel && <DeleteModel deleteModel={modelDelete} close={close} />}
      <ToastContainer />
    </ErrorHandler>
  );
}

export default TableList;
