import { Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import React, { useState, useEffect, useContext } from 'react'
import log from 'loglevel';
import ViewTenantData from './tenantData';
import ErrorModal from '../../modals/errorModal';

var apigClientFactory = require('aws-api-gateway-client').default;

const TenantSubmitRequest = ({ tenantID, stagingID, openDialog, onClose }) => {

  // const {cred} = useContext(AuthContext);
  // const {tokens} = useContext(AuthContext);
  // const accessKeyId = cred.accessKeyId;
  // const SecretKey = cred.secretAccessKey;
  // const SessionToken = cred.sessionToken;
  // const region = tokens.region;

    const config = {
      // invokeUrl: process.env.REACT_APP_SERVICE_URL,
      // region: region,
      // accessKey: accessKeyId,
      // secretKey: SecretKey,
      // sessionToken: SessionToken,
    };
  
  
    const apigClient = apigClientFactory.newClient(config);

    const [ tenantData, setTenantData ] = useState([]);
    const [tenantDialog,setTenantDialog] = useState(false);
    const [ openErrorDialog, setOpenErrorDialog ] = useState(false);  //Error dialog

    const onHandleCloseErrorDialog =() => {
        onClose();
        setOpenErrorDialog(false);
      }

      const onHandleClose = () => {
        onClose();
        setTenantDialog(false); //closes dialog box
      } 

    const getSelectedTenantData = async() => {
      var pathTemplate = "/tenantservice/tenant";
      var method = "GET";
      var pathParams = {
        //This is where path request params go.
        //userId: '1234',
      };
      var additionalParams = {
        headers: {
          "Content-Type": "application/json",
          "tenant-id": tenantID,
        },
      };

      var body = {
        //This is where you define the body of the request
      };
        try {
            await apigClient.invokeApi(pathParams, pathTemplate, method, additionalParams, body)
              .then((resp) => {
                console.log("resp", resp);
                const tenantData = resp.data;
                setTenantData(tenantData);
              });
        }
        catch (err) {
            log.error("Could not send the request");
          }

    }

    const submitRequest = async() => {
      var pathTemplate = `/tenantservice/tenant/request/${stagingID}`;
      var method = "GET";
      var pathParams = {
        //This is where path request params go.
        //userId: '1234',
      };
      var additionalParams = {
        headers: {
          "aws-key": accessKeyId,
          "aws-secret": SecretKey,
          "cli-region": region,
          "aws-session": SessionToken,
          "tenant-id": tenantID               
        },
      };

      var body = {
        //This is where you define the body of the request
      };
        try {
          await apigClient.invokeApi(pathParams, pathTemplate, method, additionalParams, body)
              .then((resp) => {
                console.log("resp", resp);
                setTenantDialog(true);
              });
           }
              catch (err) {
                log.error("Could not send the request");
                setOpenErrorDialog(true);
              }
    }

    const handleSubmit = () => {
       submitRequest();
      };

    useEffect (() => {
        getSelectedTenantData();
    },[])


  return (
    <div>
        {
      openErrorDialog && (
        <ErrorModal 
        openDialogError={openErrorDialog} 
        onHandleCloseErrorDialog  ={onHandleCloseErrorDialog} />
      )}
      {tenantDialog && <ViewTenantData 
      tenantID ={tenantID}
      open={tenantDialog} 
      handleClose={onHandleClose}/>}
         <Dialog
          open={openDialog}
          close={onClose}
         >
            <DialogContent>
          <Typography variant="h6">
            Please confirm Onboarding Details
          </Typography>
          {tenantData && tenantData.map((stagingDetails, index) => {
            return(
                <><div key={index}>
                    <p>Tenant Domain : {stagingDetails.tenantDomain}</p>
                    <p>Brand Name : {stagingDetails.tenantName}</p>
                    <p>Mail ID : {stagingDetails.tenantOwnerEmailId}</p>
                    <p>Description : {stagingDetails.tenantDescription}</p>
                    {/* <p>Brand Logo : {"emblogo.png"}</p> */}
                    <p>Theme: {stagingDetails.tenantTheme}</p>
                    <h6>Password Policy</h6>
                    <p>Password length : {stagingDetails.tenantPasswordLength} Characters</p>
                    <p>Numbers : {stagingDetails.tenantPasswordRequireNumber === true ? 'true' : 'false'}</p>
                    <p>Special Characters : {stagingDetails.tenantPasswordRequireSpecial === true ? 'true' : 'false'}</p>
                    <p>Uppercase letters : {stagingDetails.tenantPasswordRequireUppercase === true ? 'true' : 'false'}</p>
                    <p>Lowercase Letters : {stagingDetails.tenantPasswordRequireLowercase === true ? 'true' : 'false'}</p>
                    <p>Temporary Password Expiration : {stagingDetails.tenantTempPassExpiry} Days</p>
                    <p>Multi factor Authentication : {stagingDetails.tenantMfa}</p>
                    <p>Remember Device : {stagingDetails.tenantRememberDevice === true ? 'true' : 'false'}</p>
                    <p>Access To Web client : {stagingDetails.tenantWebClientAccess === true ? 'true' : 'false'}</p>
                </div><div>
                <Typography variant="inherit" color={"red"}>
            Are you sure to submit request ?
          </Typography>
          <Typography variant="inherit" color={"red"}>
            Details cannot be changed once submitted
          </Typography>
                    </div></>
            )
          })}
        </DialogContent>
            <DialogActions>
            <button style={{color:'white'}} className='btn' onClick={onClose}>Cancel</button>
            <button style={{color:'white'}} className='btn' onClick={handleSubmit}>Submit</button>
            </DialogActions>
         </Dialog>
    </div>
    
  )
}

export default TenantSubmitRequest