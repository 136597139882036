import React from "react";
import { useSelector } from "react-redux";
import Form from "./form/form";

function CreateVehicleModel({ action, goBack, selectedData }) {
  const width = useSelector((state) => state.width.width);

  return (
    <div
      style={{
        width: window.innerWidth - width - 30,
        height: window.innerHeight - 80,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Form action={action} goBack={goBack} selectedData={selectedData} />
    </div>
  );
}

export default CreateVehicleModel;
