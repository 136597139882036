import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Grid, ListItem, ListItemIcon, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import Paper from "@mui/material/Paper";
import React from "react";
import { useSelector } from "react-redux";
import MainHeader from "../../components/melecules/main-header/main-header";

function Dashboard({ editable }) {
  const CURRENT_USER_TYPE = useSelector((state) => state?.userType.userType);
  const superAdminRole = "superAdminRole";
  console.log(CURRENT_USER_TYPE);
  const isSuperAdmin =
    CURRENT_USER_TYPE &&
    CURRENT_USER_TYPE.localeCompare(superAdminRole, undefined, {
      sensitivity: "base",
    }) === 0;

  // let tenantId = 1;
  let tenantId = JSON.parse(localStorage.getItem("TenantID"));
  const cardStyles = {
    border: "1px solid #a4a5a5a8",
    borderRadius: "5%",
  };
  const cardStyles2 = {
    border: "1px solid #a4a5a5a8",
    borderRadius: "5%",
    paddingBottom: "150px",
  };
  const cardStyles3 = {
    border: "1px solid #a4a5a5a8",
    borderRadius: "5%",
    paddingBottom: "50px",
  };
  return (
    <div>
      <MainHeader title={"Dashboard"} />
      <Grid container spacing={2} sx={{ marginTop: "2px" }}>
        {isSuperAdmin ? (
          <Grid item xs={3}>
            <Card sx={cardStyles}>
              <ListItem disableGutters style={{ paddingLeft: "5px" }}>
                <ListItemIcon>
                  <FiberManualRecordIcon
                    style={{ fontSize: "small", color: "#e69704f1" }}
                  />
                </ListItemIcon>
                <Typography style={{ marginLeft: "-40px" }}>
                  Onboarded Tenants
                </Typography>
              </ListItem>
              <Typography
                variant="h5"
                color="#e69704f1"
                style={{ marginLeft: "0px", marginRight: "0px" }}
              >
                <iframe
                  src="https://monitoring.embfotaconnect.info/d-solo/f8fdca96-5686-403e-8439-6009faae6as2/frontend-stats?orgId=1&from=1690204330346&to=1690225930346&panelId=1"
                  width="100%"
                  height="100%"
                  frameBorder="0"
                ></iframe>{" "}
              </Typography>
            </Card>
          </Grid>
        ) : null}
        <Grid item xs={3}>
          <Card sx={cardStyles}>
            <ListItem disableGutters style={{ paddingLeft: "5px" }}>
              <ListItemIcon>
                <FiberManualRecordIcon
                  style={{ fontSize: "small", color: "#e69704f1" }}
                />
              </ListItemIcon>
              <Typography style={{ marginLeft: "-40px" }}>Fleets</Typography>
            </ListItem>
            <Typography
              variant="h5"
              color="#e69704f1"
              style={{ marginLeft: "0px", marginRight: "0px" }}
            >
              {isSuperAdmin ? (
                // superAdmin URL
                <iframe
                  src="https://monitoring.embfotaconnect.info/d-solo/f8fdca96-5686-403e-8439-6009faae6as2/frontend-stats?orgId=1&from=1690204363050&to=1690225963050&panelId=2"
                  width="100%"
                  height="100%"
                  frameBorder="0"
                ></iframe>
              ) : (
                // Admin URL
                <iframe
                  src={
                    "https://monitoring.embfotaconnect.info/d-solo/f8fdca96-5686-403e-8439-6009faae6fdf/frontend-stats-admin?orgId=1&from=1690125712298&to=1690298512298&var-tenant_id=" +
                    tenantId +
                    "&panelId=2"
                  }
                  width="100%"
                  height="100%"
                  frameBorder="0"
                ></iframe>
              )}
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card sx={cardStyles}>
            <ListItem disableGutters style={{ paddingLeft: "5px" }}>
              <ListItemIcon>
                <FiberManualRecordIcon
                  style={{ fontSize: "small", color: "#e69704f1" }}
                />
              </ListItemIcon>
              <Typography style={{ marginLeft: "-40px" }}>Models</Typography>
            </ListItem>
            <Typography
              variant="h5"
              color="#e69704f1"
              style={{ marginLeft: "0px", marginRight: "0px" }}
            >
              {isSuperAdmin ? (
                <iframe
                  src="https://monitoring.embfotaconnect.info/d-solo/f8fdca96-5686-403e-8439-6009faae6as2/frontend-stats?orgId=1&from=1690204392789&to=1690225992789&panelId=3"
                  width="100%"
                  height="100%"
                  frameBorder="0"
                ></iframe>
              ) : (
                <iframe
                  src={
                    "https://monitoring.embfotaconnect.info/d-solo/f8fdca96-5686-403e-8439-6009faae6fdf/frontend-stats-admin?orgId=1&from=1690125712298&to=1690298512298&var-tenant_id=" +
                    tenantId +
                    "&panelId=3"
                  }
                  width="100%"
                  height="100%"
                  frameBorder="0"
                ></iframe>
              )}
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card sx={cardStyles}>
            <ListItem disableGutters style={{ paddingLeft: "5px" }}>
              <ListItemIcon>
                <FiberManualRecordIcon
                  style={{ fontSize: "small", color: "#e69704f1" }}
                />
              </ListItemIcon>
              <Typography style={{ marginLeft: "-40px" }}>
                Onboarded Vehicles
              </Typography>
            </ListItem>
            <Typography
              variant="h5"
              color="#e69704f1"
              style={{ marginLeft: "0px", marginRight: "0px" }}
            >
              {isSuperAdmin ? (
                <iframe
                  src="https://monitoring.embfotaconnect.info/d-solo/f8fdca96-5686-403e-8439-6009faae6as2/frontend-stats?orgId=1&from=1690204414449&to=1690226014450&panelId=4"
                  width="100%"
                  height="100%"
                  frameBorder="0"
                ></iframe>
              ) : (
                <iframe
                  src={
                    "https://monitoring.embfotaconnect.info/d-solo/f8fdca96-5686-403e-8439-6009faae6fdf/frontend-stats-admin?orgId=1&from=1690125712298&to=1690298512298&var-tenant_id=" +
                    tenantId +
                    "&panelId=4"
                  }
                  width="100%"
                  height="100%"
                  frameBorder="0"
                ></iframe>
              )}
            </Typography>
          </Card>
        </Grid>
        {!isSuperAdmin ? (
          <Grid item xs={3}>
            <Card sx={cardStyles}>
              <ListItem disableGutters style={{ paddingLeft: "5px" }}>
                <ListItemIcon>
                  <FiberManualRecordIcon
                    style={{ fontSize: "small", color: "#e69704f1" }}
                  />
                </ListItemIcon>
                <Typography style={{ marginLeft: "-40px" }}>
                  Campaign
                </Typography>
              </ListItem>
              <Typography
                variant="h5"
                color="#e69704f1"
                style={{ marginLeft: "0px", marginRight: "0px" }}
              >
                <iframe
                  src="https://monitoring.embfotaconnect.info/d-solo/f8fdca96-5686-403e-8439-6009faae6fdf/frontend-stats-admin?orgId=1&var-tenant_id=def454af-9d9f-4885-92c0-59ea2ded5ed2&from=1742268841717&to=1742290441717&panelId=6"
                  width="100%"
                  height="100%"
                  frameBorder="0"
                ></iframe>
              </Typography>
            </Card>
          </Grid>
        ) : null}
      </Grid>
      {!isSuperAdmin ? (
        <Paper sx={{ width: "100%", overflow: "hidden", marginTop: "2%" }}>
          {/* <TableContainer
            sx={{ border: "1px solid #a4a5a5a8", height: "205px" }}
          > */}
          <div style={{ border: "1px solid #a4a5a5a8", height: "275px" }}>
            <ListItem disableGutters style={{ paddingLeft: "10px" }}>
              <ListItemIcon>
                <FiberManualRecordIcon
                  style={{ fontSize: "small", color: "#e69704f1" }}
                />
              </ListItemIcon>
              <Typography
                style={{
                  marginLeft: "-30px",
                }}
              >
                Onboarded Vehicles (Added New 5 Vehicles)
              </Typography>
            </ListItem>
            {isSuperAdmin ? (
              <iframe
                src="https://monitoring.embfotaconnect.info/d-solo/f8fdca96-5686-403e-8439-6009faae6as2/frontend-stats?orgId=1&from=1690204443278&to=1690226043278&panelId=5"
                width="100%"
                height="100%"
                frameBorder="0"
              ></iframe>
            ) : (
              <iframe
                src={
                  "https://monitoring.embfotaconnect.info/d-solo/f8fdca96-5686-403e-8439-6009faae6fdf/frontend-stats-admin?orgId=1&from=1690125712298&to=1690298512298&var-tenant_id=" +
                  tenantId +
                  "&panelId=5"
                }
                width="100%"
                height="100%"
                frameBorder="0"
              ></iframe>
            )}
          </div>
          {/* </TableContainer> */}
        </Paper>
      ) : null}
    </div>
  );
}

export default Dashboard;
