import { configureStore } from '@reduxjs/toolkit'
import users from "../redux/reducer/users"
import userType from './reducer/userType';
import createSagaMiddleware from"@redux-saga/core";
import rootSaga from './saga';
import tokens from './reducer/tokens';
import cred from './reducer/cred'
import width from './reducer/width';
const sagaMiddleware = createSagaMiddleware();


const store = configureStore({
    reducer: {users:users,userType:userType,tokens:tokens,cred:cred, width:width},
    
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({ thunk: false }).prepend(sagaMiddleware);
    },
});
sagaMiddleware.run(rootSaga);

export default store
