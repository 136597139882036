import { Auth } from "aws-amplify";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getUsers,
  setCred,
  setTokens,
  setUserType,
} from "../redux/actions/users";
import { getTokenCredentials, getTokens, refreshToken } from "../services/aws";
import { getRefreshTime } from "../utilities/helper";

const useCredentials = () => {
  const [loading, setLoading] = useState(true);
  const [statusCode, setStatusCode] = useState(200);
  const refreshTimerRef = useRef(null);
  const credentialsTimerRef = useRef(null);
  const dispatch = useDispatch();

  // Function to refresh IAM credentials
  const refreshIamCredentials = async () => {
    try {
      console.log("[AUTH] Refreshing IAM credentials...");

      // Clear the credentials from cache to force fresh ones
      await Auth.currentCredentials({ bypassCache: true });

      // Get refreshed credentials
      const credentials = await getTokenCredentials();

      if (!credentials) {
        console.error("[AUTH] Failed to refresh IAM credentials");
        return false;
      }

      const { accessKeyId, secretAccessKey, sessionToken, expiresAt } =
        credentials;

      // Update credentials in Redux
      dispatch(setCred({ accessKeyId, secretAccessKey, sessionToken }));

      console.log(
        `[AUTH] IAM credentials refreshed successfully. Expires at: ${new Date(
          expiresAt
        ).toLocaleString()}`
      );

      // Return IAM credential expiration
      return {
        iamExpiresAt: expiresAt,
      };
    } catch (error) {
      console.error("[AUTH] Error refreshing IAM credentials:", error);
      return false;
    }
  };

  // Set up IAM credentials refresh cycle
  const setupIamCredentialsRefresh = (expiresAt) => {
    if (credentialsTimerRef.current) {
      clearTimeout(credentialsTimerRef.current);
    }

    // Convert expiresAt to milliseconds if it's in seconds
    const expiresAtMs =
      typeof expiresAt === "number" && expiresAt > 1000000000000
        ? expiresAt
        : expiresAt * 1000;

    // Calculate time remaining (subtract 5 minutes as buffer)
    const timeRemainingMs = expiresAtMs - Date.now() - 5 * 60 * 1000;
    const minutesRemaining = Math.floor(timeRemainingMs / (1000 * 60));

    console.log(
      `[AUTH] IAM credentials will refresh in ${minutesRemaining} minutes`
    );

    if (timeRemainingMs <= 0) {
      // Refresh immediately if already expired or about to expire
      refreshIamCredentials().then((result) => {
        if (result && result.iamExpiresAt) {
          setupIamCredentialsRefresh(result.iamExpiresAt);
        } else {
          // If refresh failed, try again in 1 minute
          credentialsTimerRef.current = setTimeout(() => {
            setupIamCredentialsRefresh(Date.now() + 60000);
          }, 60000);
        }
      });
    } else {
      // Schedule refresh before expiration
      credentialsTimerRef.current = setTimeout(async () => {
        console.log("[AUTH] IAM credentials refresh timer triggered");
        const result = await refreshIamCredentials();
        if (result && result.iamExpiresAt) {
          setupIamCredentialsRefresh(result.iamExpiresAt);
        } else {
          // If refresh failed, try again in 1 minute
          credentialsTimerRef.current = setTimeout(() => {
            setupIamCredentialsRefresh(Date.now() + 60000);
          }, 60000);
        }
      }, timeRemainingMs);
    }
  };

  // Robust token refresh function
  const refreshTokenAndCredentials = async () => {
    try {
      console.log("[AUTH] Starting token refresh cycle...");

      // Refresh Cognito tokens
      const refreshedTokens = await refreshToken();

      if (!refreshedTokens) {
        console.error("[AUTH] Failed to refresh tokens");
        return false;
      }

      console.log("[AUTH] Successfully refreshed Cognito tokens");
      const { userrole, accessToken, idToken, region, exp } = refreshedTokens;

      // Update tokens in Redux
      dispatch(setUserType(userrole));
      dispatch(setTokens({ accessToken, idToken, region, exp }));

      // Now refresh IAM credentials as well
      const credentialsResult = await refreshIamCredentials();

      if (!credentialsResult) {
        console.warn(
          "[AUTH] Cognito tokens refreshed but IAM credentials refresh failed"
        );
        return { exp }; // Still return the Cognito token exp
      }

      return {
        exp,
        iamExpiresAt: credentialsResult.iamExpiresAt,
      };
    } catch (error) {
      console.error("[AUTH] Error during token refresh cycle:", error);
      return false;
    }
  };

  // Set up the next Cognito token refresh cycle
  const setupNextTokenRefreshCycle = (exp) => {
    // Clear any existing timers
    if (refreshTimerRef.current) {
      clearTimeout(refreshTimerRef.current);
    }

    // Calculate time to refresh (5 minutes before expiration)
    const refreshTime = getRefreshTime(exp, 5);

    // Set new timer
    refreshTimerRef.current = setTimeout(async () => {
      console.log("[AUTH] Cognito token refresh timer triggered");
      const result = await refreshTokenAndCredentials();

      if (result && result.exp) {
        setupNextTokenRefreshCycle(result.exp);
        if (result.iamExpiresAt) {
          setupIamCredentialsRefresh(result.iamExpiresAt);
        }
      } else {
        // If refresh failed, try again in 1 minute
        console.log("[AUTH] Refresh failed, retrying in 1 minute...");
        refreshTimerRef.current = setTimeout(() => {
          setupNextTokenRefreshCycle(Math.floor(Date.now() / 1000) + 60);
        }, 60 * 1000);
      }
    }, refreshTime);
  };

  // Initial credentials setup
  const setCredentials = async () => {
    try {
      console.log("[AUTH] Initial setup - getting tokens...");
      const tokenData = await getTokens();

      if (tokenData.error) {
        console.error("[AUTH] Error getting initial tokens:", tokenData.error);
        setStatusCode(500);
        setTimeout(() => {
          setLoading(false);
        }, 3000);
        return;
      }

      const {
        accessToken,
        exp,
        idToken,
        refreshTokens,
        region,
        userRole,
        user,
        userrole,
      } = tokenData;

      console.log(
        `[AUTH] Initial Cognito tokens acquired. Expires at: ${new Date(
          exp * 1000
        ).toLocaleString()}`
      );

      // Update Redux state with tokens
      dispatch(setUserType(userrole));
      dispatch(getUsers(user));
      dispatch(
        setTokens({
          accessToken,
          exp,
          idToken,
          refreshTokens,
          region,
          userRole,
        })
      );

      // Get initial IAM credentials
      console.log("[AUTH] Getting initial AWS IAM credentials...");
      const credentials = await getTokenCredentials();

      if (!credentials) {
        console.error("[AUTH] Failed to get initial IAM credentials");
        setStatusCode(500);
        setLoading(false);
        return;
      }

      const { accessKeyId, secretAccessKey, sessionToken, expiresAt } =
        credentials;

      console.log(
        `[AUTH] Initial AWS IAM credentials acquired. Expires at: ${new Date(
          expiresAt
        ).toLocaleString()}`
      );
      dispatch(setCred({ accessKeyId, secretAccessKey, sessionToken }));

      // Update status
      setStatusCode(200);
      setLoading(false);

      // Set up both refresh cycles
      if (exp) {
        setupNextTokenRefreshCycle(exp);
      } else {
        console.error("[AUTH] No expiration time found in token");
      }

      if (expiresAt) {
        setupIamCredentialsRefresh(expiresAt);
      } else {
        console.error("[AUTH] No expiration time found in IAM credentials");
      }
    } catch (error) {
      console.error("[AUTH] Unexpected error in setCredentials:", error);
      setStatusCode(500);
      setLoading(false);
    }
  };

  // Initial setup and cleanup
  useEffect(() => {
    // Start initial setup
    setCredentials();

    // Set up a periodic check (every 10 minutes) for both tokens and credentials
    const periodicCheckInterval = setInterval(() => {
      console.log("[AUTH] Running periodic authentication check...");

      // Check Cognito tokens
      getTokens().then((tokenData) => {
        if (tokenData.error) {
          console.warn("[AUTH] Cognito token validation failed, refreshing...");
          refreshTokenAndCredentials();
        } else {
          console.log(
            `[AUTH] Cognito tokens valid until ${new Date(
              tokenData.exp * 1000
            ).toLocaleString()}`
          );
        }
      });

      // Also check IAM credentials explicitly
      Auth.currentCredentials()
        .then((credentials) => {
          const expiration = credentials.expiration;
          const timeRemaining = expiration - new Date();
          const minutesRemaining = Math.floor(timeRemaining / (1000 * 60));

          console.log(
            `[AUTH] IAM credentials valid for ${minutesRemaining} more minutes`
          );

          // If less than 10 minutes remaining, refresh proactively
          if (minutesRemaining < 10) {
            console.warn(
              `[AUTH] IAM credentials expiring soon, refreshing proactively...`
            );
            refreshIamCredentials().then((result) => {
              if (result && result.iamExpiresAt) {
                setupIamCredentialsRefresh(result.iamExpiresAt);
              }
            });
          }
        })
        .catch((error) => {
          console.error("[AUTH] Error checking IAM credentials:", error);
          refreshIamCredentials();
        });
    }, 10 * 60 * 1000); // Every 10 minutes

    // Clean up function
    return () => {
      console.log("[AUTH] Cleaning up authentication timers");
      if (refreshTimerRef.current) {
        clearTimeout(refreshTimerRef.current);
      }
      if (credentialsTimerRef.current) {
        clearTimeout(credentialsTimerRef.current);
      }
      clearInterval(periodicCheckInterval);
    };
  }, []);

  return { setCredentials, loading, statusCode };
};

export default useCredentials;
