import { useAuthenticator } from "@aws-amplify/ui-react";
import { KeyboardArrowDown, Logout } from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { IoIosRefreshCircle } from "react-icons/io";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../App.jsx";
import "./MainHeader.css"; // Import CSS file for styles

const MainHeader = ({
  title,
  searchBar,
  button,
  onClick,
  tab,
  onSearch,
  onReset,
}) => {
  const width = useSelector((state) => state.width.width);
  const { userLogo } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [selected, setSelected] = useState(1);
  const { user, signOut } = useAuthenticator((context) => [context.user]);

  const selectTap = (id) => {
    setSelected(id);
    onClick(id);
  };
  const [searchTerm, setSearchTerm] = useState("");
  // const searchvalue = searchTerm

  const handleSearch = () => {
    if (searchTerm.trim()) {
      onSearch(searchTerm, () => setSearchTerm(""));
    }
  };

  const handleReset = () => {
    setSearchTerm("");
    if (onSearch) {
      onSearch("");
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    signOut();
    handleClose();
    localStorage.clear();
    navigate("/");
  };

  return (
    <>
      <div
        className="main-header"
        style={{ width: window.innerWidth - width - 30 }}
      >
        <p className="header-title" style={{ width: window.innerWidth / 4 }}>
          {title}
        </p>

        <div className="tab">
          {tab &&
            tab.map((item) => (
              <div
                className="button"
                onClick={() => selectTap(item?.id)}
                key={item?.id}
                style={{
                  backgroundColor: selected == item?.id ? "#19172C" : "#d3d3d3",
                  width: window.innerWidth / 9,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  className="button-text"
                  style={{ color: selected == item?.id ? "#fff" : "#000" }}
                >
                  {item.title}
                </p>
              </div>
            ))}
        </div>
        {searchBar ? (
          <>
            <InputGroup style={{ width: "300px" }}>
              <Form.Control
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <Button
                as="button" // Force it to be a regular button
                style={{
                  width: "auto",
                  minWidth: "auto",
                  padding: "0 8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                // variant="outline-secondary"
                onClick={handleSearch}
              >
                <FaSearch size={14} />
              </Button>
            </InputGroup>
            <IoIosRefreshCircle
              style={{ color: "rgb(255, 87, 51)", cursor: "pointer" }}
              size={30}
              title="Reset Search"
              onClick={handleReset}
            />
          </>
        ) : (
          <div style={{ width: "300px" }} />
        )}
        <Box>
          <IconButton
            onClick={handleClick}
            sx={{
              borderRadius: 2,
              border: "1px solid",
              borderColor: "divider",
              padding: 1,
              "&:hover": {
                backgroundColor: "action.hover",
              },
            }}
          >
            <img
              src={userLogo}
              alt="https://www.iconpacks.net/icons/2/free-user-icon-3296-thumb.png"
              style={{ width: 120, height: 30, objectFit: "contain" }}
            />
            <KeyboardArrowDown
              sx={{
                ml: 0.5,
                transform: open ? "rotate(180deg)" : "none",
                transition: "transform 0.2s",
              }}
            />
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              sx: {
                width: 220,
                mt: 1.5,
                "& .MuiMenuItem-root": {
                  px: 2,
                  py: 1,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <Box sx={{ px: 2, py: 1.5 }}>
              <Typography variant="subtitle1" fontWeight="600" noWrap>
                {user?.username}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                noWrap
                sx={{ mt: 0.5 }}
              >
                {user?.attributes?.email}
              </Typography>
            </Box>

            <Divider
              sx={{
                my: 0.5,
                borderColor: "rgba(69, 68, 68, 0.73)",
                borderWidth: "1px",
              }}
            />

            <MenuItem onClick={handleLogout} sx={{ gap: 1.5 }}>
              <Logout fontSize="small" color="action" />
              <Typography variant="body2">Logout</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </div>
    </>
  );
};

export default MainHeader;
