import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaAngleDown, FaAngleUp } from "../../../constants/icons";
import "./single-side-bar.css";

function SingleSideBar({ item, child, openDrawer,activeTab,index }) {
  const [showChild, setShowChild] = useState(false);
  const navigate = useNavigate();

  const Box = ({ data, child }) => {
    const onClick = () => {
      if (data?.child) {
        setShowChild(!showChild);
        openDrawer(!showChild,index);
      } else {
        openDrawer(false,index);
        navigate(data.path);
        setShowChild(false);
      }
    };

    

    return (
      <div className="side-bar" onClick={onClick} style={{backgroundColor:activeTab == index?"#FF5733":null,borderRadius:10}}
           >
        <div className="icon-div" style={{ marginLeft: child ? 40 : 0,  }}>
          {data.icon}
          <div className="hover-text">{data.name}</div>
        </div>
        <div className="title-div">
          <p className="title">{data.name}</p>
        </div>
        <div className="icon-div">
          {data?.child && (
            <>
              {showChild ? (
                <FaAngleUp style={{ fontSize: 18, marginLeft: 10 }} />
              ) : (
                <FaAngleDown style={{ fontSize: 18, marginLeft: 10 }} />
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <Box data={item} />
      {showChild && (
        <>
          {item.child.map((i) => (
            <Box data={i} child={true} key={i?.id} />
          ))}
        </>
      )}
    </>
  );
}

export default SingleSideBar;
