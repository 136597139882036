import React, { useLayoutEffect, useRef, useState } from "react";
import {
  convertUTCDateToLocalDate,
  getLocalStorageData,
} from "../../../utilities/helper";
import ReactPaginate from "react-paginate";
import { FaBackward, FaForward } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";

import Paper from "@mui/material/Paper";
import CommonTable from "../../../components/organizer/common-table/common-table";
import ErrorHandler from "../../../components/organizer/erro-handler/error-handler";
import useAwsApiRequest from "../../../hook/useAwsApiRequest";
import {
  ArrowBackIosNewOutlinedIcon,
  ArrowForwardIosOutlinedIcon,
} from "../../../constants/icons";
import moment from "moment";
import DeleteModel from "../../../components/melecules/delete-model/delete-model";
import { ToastContainer, toast } from "react-toastify";
import SubmitPopup from "../submit-popup/submit-popup";
import { useSelector } from "react-redux";
import SockJsClient from "react-stomp";
import StatusPopup from "../status-popup/status-popup";

function TableListHistory({ searchQuery, editData, successData }) {
  const { statusCode, loading, data, apiRequest } = useAwsApiRequest();
  const tenant = getLocalStorageData("TenantID");
  const responseData = useRef();
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0); // Current page number
  const [totalPage, setTotalPage] = useState(0);
  const [filteredData, setFilteredData] = useState([]); // State variable to hold filtered data
  const [searchTerm, setSearchTerm] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedTenantID, setSelectedTenantID] = useState(null);
  const [messages, setMessages] = useState([]); //state for updating messages from websocket
  const [statusOpen, setStatusOpen] = useState(false);

  const cred = useSelector((state) => state.cred.cred);
  const tokens = useSelector((state) => state.tokens.tokens);

  const [tableHeader, setTableHeader] = useState([
    { id: 1, name: "Sl.No" },
    { id: 2, name: "Brand Name" },
    { id: 3, name: "Brand details" },
    { id: 5, name: "Requested On" },
    { id: 6, name: "Requested By" },
    { id: 7, name: "Status" },
    { id: 8, name: "Action" },
  ]);

  const [deleteModel, setDeleteModel] = useState(false);

  const selectedData = useRef(null);
  const config = {
    invokeUrl: process.env.REACT_APP_SERVICE_URL,
    region: tokens?.region,
    accessKey: cred?.accessKeyId,
    secretKey: cred?.secretAccessKey,
    sessionToken: cred?.sessionToken,
  };
  const socketURL = process.env.REACT_APP_SCOKET_URL;

  useLayoutEffect(() => {
    if (successData) {
      console.log("successData",successData)
      responseData.current = successData;
      const filteredData = filterFields(successData, true);
      setTableData(filteredData);
      setFilteredData(filteredData);
      const dataCount = filteredData.length;
      const itemsPerPage = 10;
      const totalPages = Math.ceil(dataCount / itemsPerPage);
      setTotalPage(totalPages);
    }
  }, [successData]);

  useLayoutEffect(() => {
    if (searchQuery !== undefined) {
      setSearchTerm(searchQuery);
    }
  }, [searchQuery]);

  useLayoutEffect(() => {
    if (searchTerm.trim() !== "") {
      const filtered = tableData.filter((item) =>
        item.fleet_name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filtered);
      setCurrentPage(0); // Reset to first page when search term changes
    } else {
      setFilteredData(tableData);
    }
  }, [searchTerm, tableData]);

  const filterFields = (data, hide) => {
    return data.map((item, index) => {
      const {
        tenant_domain,
        tenant_description,
        requested_on,
        requested_by,
        status,
        tenant_id,
      } = item; // Extract necessary properties

      if (hide) {
        return {
          id: index + 1,
          tenant_domain: tenant_domain,
          tenant_description: tenant_description,
          requested_on: moment(requested_on).format("MMMM Do YYYY, h:mm a"),
          requested_by: requested_by,
          status: status == "P" ? "Pending" : "Success",
          action: "tenant_action",
          tenant_id: tenant_id,
        };
      } else {
        return {
          id: index + 1,
          tenant_domain: tenant_domain,
          tenant_description: tenant_description,
          requested_on: moment().format("MMMM Do YYYY, h:mm a"),
          requested_by: requested_by,
          status: status == "P" ? "Pending" : "Success",
          action: "tenant_action",
          tenant_id: tenant_id,
        };
      }
    });
  };

  const action = (a, data) => {
    console.log("data",data);

    if (a == "edit") {
      editData(
        3,
        responseData.current.find((x) => x.fleet_name == data.fleet_name)
      );
    }
    if (a == "delete") {
      setDeleteModel(true);
      selectedData.current = responseData.current.find(
        (x) => x.fleet_name == data.fleet_name
      );
    }
    if (a == "submit") {
      const index = successData.findIndex(
        (x) => x?.tenant_id == data?.tenant_id
      );
      console.log("index",index)
      setSelectedIndex(index);
      setSelectedTenantID(data?.tenant_id);
      setOpenSuccess(true);
    }
    if (a == "status") {
      const index = successData.findIndex(
        (x) => x?.tenant_id == data?.tenant_id
      );
      setSelectedTenantID(data?.tenant_id);
      setSelectedIndex(index);
      setStatusOpen(true);
    }
    if (a == "delete") {
    }
  };

  const showMoreData = (show) => {
    const filteredData = filterFields(responseData.current, show);
    setTableData(filteredData);
    if (show) {
      // If hide is true, remove the last 2 objects from the tableHeader array
      setTableHeader((prevHeader) =>
        prevHeader.slice(0, prevHeader.length - 1)
      );
    } else {
      // If hide is false or undefined, reset the tableHeader array to its original state
      setTableHeader([
        { id: 1, name: "Sl.No" },
        { id: 2, name: "Brand Name" },
        { id: 3, name: "Brand details" },
        { id: 5, name: "Requested On" },
        { id: 6, name: "Requested By" },
        { id: 7, name: "Status" },
        { id: 8, name: "Action" },
      ]);
    }
  };

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected); // Update the current page
  };

  const close = () => {
    setDeleteModel(false);
  };

  const modelDelete = async () => {
    let data = {
      pathParam: {},
      endpoint: `http://localhost:8082/fleetservice/fleet/${selectedData.current?.fleet_id}`,
      method: "DELETE",
      additionalParams: {
        headers: {
          "tenant-id": "f635e446-4d25-455d-8733-e49c8b4dbd62",
        },
      },
      body: {},
    };

    const response = await apiRequest(data);
    if (response?.status == 204) {
      toast.success("Successfully Deleted Fleet", {
        position: "top-right",
      });
      close();
      // getFleet();
    } else {
      toast.error("Network Error", {
        position: "top-right",
      });
      close();
    }
  };

  const submitRequest = async (tenantData) => {
    let data = {
      pathParam: {},
      endpoint: `/tenantservice/tenant/request/${tenantData?.staging_id}`,
      method: "GET",
      headerCred: {
        "aws-key": cred?.accessKeyId,
        "aws-secret": cred?.secretAccessKey,
        "cli-region": tokens?.region,
        "aws-session": cred?.sessionToken,
        "tenant-id": tenantData?.tenant_id
      },
      body: {},
    };

    const toast = true;
    const response = await apiRequest(data, toast);
    if (response) {
      onClose();
    }
  };

  const onClose = async () => {
    setOpenSuccess(false);
  };

  const onMessageReceived = (msg) => {
    setMessages(messages.concat(msg));
    const response = JSON.stringify(messages);
    localStorage.setItem(selectedTenantID, response);
    console.log("messages",messages);
  };

  let onConnected = () => {
    // log.info("Connected!!");
  };

  let onDisconnect = () => {
    // console.log("Disconnected!");
  };

  const handleCloseStatus = () => {
    setStatusOpen(false);
  };

  const customHeaders = {
    "Access-Control-Allow-Origin": "https://embfotaconnect.info",
  };
  
  const SOCKET_URL = `${socketURL}`;

  const itemsPerPage = 10;
  const offset = currentPage * itemsPerPage; // Calculate offset for pagination
  const currentPageData = filteredData.slice(offset, offset + itemsPerPage);
  return (
    <ErrorHandler statusCode={200} loading={loading}>
      {/* <SockJsClient
        url={SOCKET_URL}
        headers={customHeaders}
        topics={["/topic/group"]}
        onConnect={onConnected}
        onDisconnect={onDisconnect}
        onMessage={(msg) => onMessageReceived(msg)}
        debug={false}
      /> */}
      <Paper sx={{ width: "100%", overflow: "hidden", marginTop: "2%" }}>
        <CommonTable
          tableHeader={tableHeader}
          data={currentPageData}
          onClick={action}
          showMoreData={showMoreData}
          showMoreIcon={false}
          from={"tenant"}
        />
        <br></br>

        <div
          style={{
            border: "1px solid #d3d3d3",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-evenly",
            padding: "5px",
            paddingTop: "15px",
          }}
        >
          <p
            style={{
              fontWeight: 600,
              color: "gray",
              fontSize: 13,
              fontFamily: "Rubik",
            }}
          >
            Showing {offset + 1} to {offset + currentPageData.length} of{" "}
            {filteredData.length} entries
          </p>
          <div style={{ marginTop: 2 }}>
            <ReactPaginate
              previousLabel={
                <ArrowBackIosNewOutlinedIcon
                  style={{ fontSize: 14, fontWeight: 600 }}
                />
              }
              nextLabel={
                <ArrowForwardIosOutlinedIcon
                  style={{ fontSize: 14, fontWeight: 600 }}
                />
              }
              breakLabel={"...."}
              marginPagesDisplayed={2}
              pageCount={totalPage}
              onPageChange={handlePageClick}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttns"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
        </div>
      </Paper>
      {deleteModel && <DeleteModel deleteModel={modelDelete} close={close} />}
      {successData?.length && selectedIndex >= 0 ? (
        <SubmitPopup
          openDialog={openSuccess}
          onClose={onClose}
          tenantData={successData[selectedIndex]}
          handleSubmit={submitRequest}
        />
      ) : null}

      {/* {selectedTenantID ? ( */}
        <StatusPopup
          tenantID={selectedTenantID}
          open={statusOpen}
          handleClose={handleCloseStatus}
        />
      {/* ) : null} */}

      <ToastContainer />
    </ErrorHandler>
  );
}

export default TableListHistory;
