import Paper from "@mui/material/Paper";
import moment from "moment";
import React, { useLayoutEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteModel from "../../../components/melecules/delete-model/delete-model";
import CommonTable from "../../../components/organizer/common-table/common-table";
import ErrorHandler from "../../../components/organizer/erro-handler/error-handler";
import {
  ArrowBackIosNewOutlinedIcon,
  ArrowForwardIosOutlinedIcon,
} from "../../../constants/icons";
import useAwsApiRequest from "../../../hook/useAwsApiRequest";
import { getLocalStorageData } from "../../../utilities/helper";
import "../../vehicleModel/viewVehicleModel.css";

function TableList({ searchQuery, editData, editable }) {
  const { statusCode, loading, data, apiRequest } = useAwsApiRequest();
  const tenant = getLocalStorageData("TenantID");
  const responseData = useRef();
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0); // Current page number
  const [totalPage, setTotalPage] = useState(0);
  const [filteredData, setFilteredData] = useState([]); // State variable to hold filtered data
  const [searchTerm, setSearchTerm] = useState("");

  const headers = editable
    ? [
        { id: 1, name: "Sl.No" },
        { id: 2, name: "Model Name" },
        { id: 3, name: "Engine Type" },
        { id: 4, name: "Year Of introduction" },
        { id: 5, name: "Description" },
        { id: 8, name: "Action" },
      ]
    : [
        { id: 1, name: "Sl.No" },
        { id: 2, name: "Model Name" },
        { id: 3, name: "Engine Type" },
        { id: 4, name: "Year Of introduction" },
        { id: 5, name: "Description" },
      ];

  const [tableHeader, setTableHeader] = useState(headers);

  const [deleteModel, setDeleteModel] = useState(false);

  const selectedData = useRef(null);

  useLayoutEffect(() => {
    getVehicleModel();
  }, []);

  useLayoutEffect(() => {
    if (searchQuery !== undefined) {
      setSearchTerm(searchQuery);
    }
  }, [searchQuery]);

  useLayoutEffect(() => {
    if (searchTerm.trim() !== "") {
      const filtered = tableData.filter((item) =>
        item.model_name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filtered);
      setCurrentPage(0); // Reset to first page when search term changes
    } else {
      setFilteredData(tableData);
    }
  }, [searchTerm, tableData]);

  const getVehicleModel = async () => {
    let data = {
      pathParam: {},
      endpoint: "http://localhost:8080/vehicleservice/vehiclemodel",
      method: "GET",
      body: {},
    };
    const response = await apiRequest(data);
    if (response?.vehicle_models?.length) {
      responseData.current = response.vehicle_models;
      const filteredData = filterFields(response.vehicle_models, true);
      setTableData(filteredData);
      setFilteredData(filteredData); // Set filtered data initially
      const dataCount = filteredData.length;
      const itemsPerPage = 10;
      const totalPages = Math.ceil(dataCount / itemsPerPage);
      setTotalPage(totalPages);
    }
  };

  const filterFields = (data, hide) => {
    return data.map((item, index) => {
      const {
        year_of_introduction,
        engine_type,
        description,
        model_name,
        created_at,
        updated_at,
      } = item; // Extract necessary properties

      if (hide) {
        return {
          id: index + 1,
          model_name: model_name,
          engine_type: engine_type,
          year_of_introduction: year_of_introduction,
          description: description,
          action: "action",
          // created_at: moment(created_at).format("MMMM Do YYYY, h:mm a"),
          // updated_at: moment(updated_at).format("MMMM Do YYYY, h:mm a"),
        };
      } else {
        return {
          id: index + 1,
          model_name: model_name,
          engine_type: engine_type,
          year_of_introduction: year_of_introduction,
          description: description,
          action: "action",
          created_at: moment(created_at).format("MMMM Do YYYY, h:mm a"),
          updated_at: moment(updated_at).format("MMMM Do YYYY, h:mm a"),
        };
      }
    });
  };

  // Update filtered data when search term changes

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected); // Update the current page
  };

  const itemsPerPage = 10;
  const offset = currentPage * itemsPerPage; // Calculate offset for pagination
  const currentPageData = filteredData.slice(offset, offset + itemsPerPage);

  const showMoreData = (show) => {
    const filteredData = filterFields(responseData.current, show);
    setTableData(filteredData);
    if (show) {
      // If hide is true, remove the last 2 objects from the tableHeader array
      setTableHeader((prevHeader) =>
        prevHeader.slice(0, prevHeader.length - 2)
      );
    } else {
      // If hide is false or undefined, reset the tableHeader array to its original state
      setTableHeader([
        { id: 1, name: "Sl.No" },
        { id: 2, name: "Model Name" },
        { id: 3, name: "Engine Type" },
        { id: 4, name: "Year Of introduction" },
        { id: 5, name: "Description" },
        { id: 8, name: "Action" },
        { id: 6, name: "Created At" },
        { id: 7, name: "Updated At" },
      ]);
      // setTableHeader(headers);
    }
  };

  const action = (a, data) => {
    if (a == "edit") {
      editData(
        3,
        responseData.current.find((x) => x.model_name == data.model_name)
      );
    } else {
      setDeleteModel(true);
      selectedData.current = responseData.current.find(
        (x) => x.model_name == data.model_name
      );
    }
  };

  const modelDelete = async () => {
    let data = {
      pathParam: {},
      endpoint: `http://localhost:8080/vehicleservice/vehiclemodel/${selectedData.current?.model_id}`,
      method: "DELETE",
      body: {},
    };

    const response = await apiRequest(data);
    if (response?.status == 204) {
      toast.success("Successfully Deleted Vehicle Model", {
        position: "top-right",
      });
      close();
      getVehicleModel();
    } else {
      toast.error("Network Error", {
        position: "top-right",
      });
      close();
    }
  };

  const close = () => {
    setDeleteModel(false);
  };

  return (
    <ErrorHandler statusCode={200} loading={loading}>
      <Paper sx={{ width: "100%", overflow: "hidden", marginTop: "1%" }}>
        <CommonTable
          editable={editable}
          tableHeader={tableHeader}
          data={currentPageData}
          onClick={action}
          showMoreData={showMoreData}
        />
        <br />
        <div
          style={{
            border: "1px solid #d3d3d3",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-evenly",
            padding: "5px",
            paddingTop: "15px",
          }}
        >
          <p
            style={{
              fontWeight: 600,
              color: "gray",
              fontSize: 13,
              fontFamily: "Rubik",
            }}
          >
            Showing {offset + 1} to {offset + currentPageData.length} of{" "}
            {filteredData.length} entries
          </p>
          <div style={{ marginTop: 2 }}>
            <ReactPaginate
              previousLabel={
                <ArrowBackIosNewOutlinedIcon
                  style={{ fontSize: 14, fontWeight: 600 }}
                />
              }
              nextLabel={
                <ArrowForwardIosOutlinedIcon
                  style={{ fontSize: 14, fontWeight: 600 }}
                />
              }
              breakLabel={"...."}
              marginPagesDisplayed={2}
              pageCount={totalPage}
              onPageChange={handlePageClick}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttns"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
        </div>
      </Paper>
      {deleteModel && <DeleteModel deleteModel={modelDelete} close={close} />}
      <ToastContainer />
    </ErrorHandler>
  );
}

export default TableList;
