import React from 'react'

const CheckBox = ({ label, name, onChange, value }) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: "5px",
      }}
    >
      <input
        className="form-check-input"
        name={name}
        type="checkbox"
        checked={value}
        onChange={onChange} // Call onChange function when radio input changes
      />
      <label
        style={{
          fontSize: 11,
          fontWeight: 600,
          color: "black",
          marginTop: 5,
          marginLeft: 10,
          fontFamily:"Rubik"
        }}
      >
        {label}
      </label>
    </div>
  );
};

export default CheckBox;
