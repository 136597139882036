import React from "react";
import indicator from "../../assets/lottie/loader.json";
import Lottie from "react-lottie";
import { height } from "../../constants/constants";
import { useSelector } from "react-redux";

function Loader() {
  const width = useSelector((state) => state.width.width);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: indicator, 
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height:height,
        width: (window.innerWidth - width)-20 ,
        position:"absolute",
        bottom:0,
        backgroundColor:"rgba(255,255,255,0.5)",
        zIndex:1000
      }}
    >
      <Lottie options={defaultOptions} height={50} width={50} />
    </div>
  );
}

export default Loader;
