import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useAwsApiRequest from "../../../hook/useAwsApiRequest";
import "./submitPopup.css";

const SubmitPopup = ({ openDialog, onClose, tenantData, handleSubmit }) => {
  console.log("tenantData", tenantData);
  const { statusCode, loading, data, error, apiRequest } = useAwsApiRequest();

  const [selectedTenant, setSelectedTenant] = useState([]);
  
  useEffect(() => {
    getSelectedTenantData();
  }, []);

  const getSelectedTenantData = async () => {
    const data = {
      pathParam: {},
      endpoint: `http://localhost:8007/tenantservice/tenant`,
      method: "GET",
      body: {},
      defaultTen: tenantData?.tenant_id,
    };

    const response = await apiRequest(data);

    if (response?.length) {
      setSelectedTenant(response);
    }
  };

  return (
    <Dialog open={openDialog} onClose={onClose}>
      <DialogContent>
        <Typography variant="h6">Please confirm Onboarding Details</Typography>
        {selectedTenant.length > 0 &&
          selectedTenant.map((stagingDetails, index) => (
            <div key={index}>
              <p>Tenant Domain: {stagingDetails?.tenantDomain}</p>
              <p>Brand Name: {stagingDetails?.tenantName}</p>
              <p>Mail ID: {stagingDetails?.tenantOwnerEmailId}</p>
              <p>Description: {stagingDetails?.tenantDescription}</p>
              <p>Theme: {stagingDetails?.tenantTheme}</p>
              <h6>Password Policy</h6>
              <p>Password length: {stagingDetails?.tenantPasswordLength} Characters</p>
              <p>Numbers: {stagingDetails?.tenantPasswordRequireNumber ? "true" : "false"}</p>
              <p>Special Characters: {stagingDetails?.tenantPasswordRequireSpecial ? "true" : "false"}</p>
              <p>Uppercase letters: {stagingDetails?.tenantPasswordRequireUppercase ? "true" : "false"}</p>
              <p>Lowercase Letters: {stagingDetails?.tenantPasswordRequireLowercase ? "true" : "false"}</p>
              <p>Temporary Password Expiration: {stagingDetails?.tenantTempPassExpiry} Days</p>
              <p>Multi factor Authentication: {stagingDetails?.tenantMfa}</p>
              <p>Remember Device: {stagingDetails?.tenantRememberDevice ? "true" : "false"}</p>
              <p>Access To Web client: {stagingDetails?.tenantWebClientAccess ? "true" : "false"}</p>
              <div>
                <Typography variant="body2" color="error">
                  Are you sure to submit request?
                </Typography>
                <Typography variant="body2" color="error">
                  Details cannot be changed once submitted
                </Typography>
              </div>
            </div>
          ))}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSubmit(tenantData)}
          disabled={loading}
        >
          {loading ? <div className="spinner"></div> : "Submit"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SubmitPopup;
