import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import UserRoleManager from "./user-role-manager";

function index() {
  const userType = useSelector((state) => state.userType.userType);

  return (
    <div>
      {
        userType && (
          <UserRoleManager userType={userType}/>
        )
      }
    </div>
  );
}

export default index;
