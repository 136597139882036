import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Amplify } from "aws-amplify";
import queryString from "query-string";
import React, { createContext, useEffect, useState } from "react";
import SnackbarComponent from "./components/melecules/snack-bar/snack-bar-component";
import ErrorHandler from "./components/organizer/erro-handler/error-handler";
import useAwsApiRequest from "./hook/useAwsApiRequest";
import useCredentials from "./hook/useCredentials";
import Router from "./route/index";
import { getConfigData } from "./services/aws";

export const UserContext = createContext();

Amplify.configure(getConfigData());
const query = queryString.parse(window.location.search);

const App = ({ signOut, user }) => {
  const { setCredentials, loading, statusCode } = useCredentials();
  const { apiRequest } = useAwsApiRequest();

  const [userLogo, setuserLogo] = useState("");

  useEffect(() => {
    getTenantData();
  }, []);

  const getTenantData = async () => {
    let data = {
      pathParam: {},
      endpoint: "http://localhost:8080/tenantservice/tenant",
      method: "GET",
      body: {},
    };
    const resp = await apiRequest(data);
    const Data = resp[0].tenantLogoFilename;
    setuserLogo(Data);
  };

  useEffect(() => {
    if (user) {
      setCredentials();
    }
  }, [user]);

  return (
    <UserContext.Provider value={{ userLogo }}>
      <ErrorHandler statusCode={statusCode} loading={loading}>
        <div
          style={{
            // backgroundImage: `url(${bg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            minHeight: "100vh",
            opacity: "0.2px",
          }}
        >
          <Router />
        </div>
        <SnackbarComponent />
      </ErrorHandler>
    </UserContext.Provider>
  );
};

export default withAuthenticator(App, { hideSignUp: true });
