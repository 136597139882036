import React from "react";

const DeleteModel = ({close,deleteModel}) => {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        alignItems: "center",
        backgroundColor: "rgba(58,58,58,0.4)",
        position: "absolute",
        bottom: 0,
        justifyContent: "center",
        display: "flex",
      }}
    >
      <div
        style={{
          width: 280,
          height: 120,
          backgroundColor: "white",
          borderRadius: 5,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          fontFamily:"Rubik"
        }}
      >
        <p style={{fontSize:"14px",fontWeight:500,fontFamily: "Rubik"}}>Are you sure you want to delete this?</p>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-evenly",
            cursor:"pointer"
          }}
        >
          <div
            style={{
              width: 80,
              height: 40,
              borderRadius: 5,
              alignItems: "center",
              justifyContent: "center",
              boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.3)",
              display: "flex",
              fontWeight:500,
              fontSize:14,
              fontFamily:"Rubik"
            }}
            onClick={close}
          >
            {"Cancel"}
          </div>
          <div
            style={{
              display: "flex",
              width: 80,
              height: 40,
              borderRadius: 5,
              alignItems: "center",
              justifyContent: "center",
              boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.3)",
              backgroundColor: "red",
              color:"white",
              fontWeight:500,
              fontSize:14,
              fontFamily:"Rubik"
            }}
            onClick={deleteModel}
          >
            {"Delete"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteModel;
