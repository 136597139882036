import { Grid } from "@mui/material";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { Button, FormControl } from "react-bootstrap";
import MainHeader from "../../components/melecules/main-header/main-header";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const TelemeticsScreen = () => {
  const [vin, setVin] = useState("");
  const [selectedVin, setSelectedVin] = useState("");

  const handleChange = (event) => {
    setVin(event.target.value);
  };

  const getData = () => {
    setSelectedVin(vin);
  };

  const tenantId = JSON.parse(localStorage.getItem("TenantID"));

  return (
    <div>
      <MainHeader title={"Telematics"} />
      <Grid
        container
        spacing={2}
        alignItems="center"
        sx={{ marginTop: "5px", marginLeft: "2px" }}
      >
        <Grid item>
          <FormControl
            placeholder="Enter VIN Number"
            value={vin}
            onChange={handleChange}
          />
        </Grid>
        <Grid item>
          <Button title="Get telematics data" onClick={getData}>
            Get Data
          </Button>
        </Grid>
      </Grid>
      <div>
        <iframe
          src={
            "https://monitoring.embfotaconnect.info/d/fa38815f-273e-4b8c-b348-2141b2389629/telematics-data?orgId=1&refresh=10s&var-VIN=" +
            selectedVin +
            "&from=now-1h&to=now&theme=light&kiosk"
          }
          width="95%"
          height="100%"
          frameBorder="0"
          style={{ position: "absolute" }}
        ></iframe>
      </div>
    </div>
  );
};

export default TelemeticsScreen;
