import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import { animated, useSpring } from "@react-spring/web";
import React, { useState } from "react";
import { FiEdit } from "react-icons/fi";
import { useSelector } from "react-redux";
import { primary } from "../../../constants/color";

function CommonTable({
  tableHeader,
  data,
  onClick,
  showMoreData,
  showMoreIcon,
  from,
}) {
  const [windowWidth, setWidth] = useState(0);
  const width = useSelector((state) => state.width.width);
  const [showMore, setShowMore] = useState(true);

  const visibleMoreData = () => {
    setShowMore(!showMore);
    showMoreData(!showMore);
  };

  // Fade-in and zoom-in animation
  const animationProps = useSpring({
    from: { opacity: 0, transform: "scale(0.9)" },
    to: { opacity: 1, transform: "scale(1)" },
    config: { duration: 500 }, // Adjust the duration as needed
  });

  return (
    <animated.div
      style={{ ...animationProps, width: window.innerWidth - width - 29 }}
    >
      <div
        style={{
          width: "100%",
          backgroundColor: "rgba(121, 184, 255, 0.3)",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {tableHeader.map((item, index) => (
          <div
            key={index}
            style={{
              height: 45,
              display: "flex",
              alignItems: "center",
              width: index
                ? item?.name === "action"
                  ? 130
                  : (window.innerWidth - width - 20) / (tableHeader.length - 1)
                : 50,
              fontWeight: 600,
              fontSize: 13,
              fontFamily: "Rubik",
              paddingLeft: 10,
            }}
          >
            <p style={{ margin: 0 }}>{item.name}</p>
          </div>
        ))}
        <div
          style={{
            position: "absolute",
            right: 20,
            alignItems: "center",
            justifyContent: "center",
            marginTop: 10,
          }}
        >
          <ReadMoreIcon
            style={{
              fontSize: "120%",
              color: "#4822B4",
              display: showMoreIcon !== false ? "flex" : "none",
            }}
            title={"Update"}
            onClick={visibleMoreData}
          />
          <p
            style={{
              fontSize: 6,
              color: "gray",
              display: showMoreIcon !== false ? "flex" : "none",
              fontFamily: "Rubik",
            }}
          >
            {!showMore ? "Show Less" : "Show More"}
          </p>
        </div>
      </div>
      <div
        style={{
          height: window.innerHeight - 285,
          width: "100%",
          overflowY: "scroll",
          scrollbarWidth: "none" /* For Firefox */,
          "-ms-overflow-style": "none" /* For Internet Explorer and Edge */,
        }}
      >
        {data.length ? (
          <>
            {data.map((vehicleModel, i) => (
              <div
                key={vehicleModel.id}
                style={{
                  backgroundColor: i % 2 ? "rgba(121, 184, 255, 0.1)" : "white",
                  width: "100%",
                  height: 45,
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: "1px solid #d3d3d3",
                }}
              >
                {Object.entries(vehicleModel).map(([key, value], index) => (
                  <div
                    key={key}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: index
                        ? (window.innerWidth - width - 20) /
                          (tableHeader.length - 1)
                        : 50,
                      // width: window.innerWidth - width - 20,
                      fontWeight: 500,
                      fontSize: 12,
                      fontFamily: "Rubik",
                      textAlign: "center",
                      paddingLeft: 10,
                    }}
                  >
                    {value === "action" ? (
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <FiEdit
                          style={{
                            fontSize: "120%",
                            color: "#4822B4",
                          }}
                          title={"Update"}
                          onClick={() => onClick("edit", vehicleModel)}
                        />
                        <DeleteForeverIcon
                          style={{
                            fontSize: "140%",
                            color: "red",
                            marginLeft: 10,
                          }}
                          title={"Delete"}
                          onClick={() => onClick("delete", vehicleModel)}
                        />
                      </div>
                    ) : value === "delete" ? (
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <DeleteForeverIcon
                          style={{
                            fontSize: "140%",
                            color: "red",
                            marginLeft: 10,
                          }}
                          title={"Delete"}
                          onClick={() => onClick("delete", vehicleModel)}
                        />
                      </div>
                    ) : value === "tenant_action" ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-evenly",
                          height: "40px",
                        }}
                      >
                        <div
                          style={{
                            width: "60px",
                            height: "30px",
                            backgroundColor: primary,
                            fontSize: "12px",
                            fontFamily: "Rubik",
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "5px",
                          }}
                          onClick={() => onClick("submit", vehicleModel)}
                        >
                          Submit
                        </div>
                        <div
                          style={{
                            width: "60px",
                            height: "30px",
                            backgroundColor: primary,
                            fontSize: "12px",
                            fontFamily: "Rubik",
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "5px",
                            marginLeft: "2px",
                          }}
                          onClick={() => onClick("status", vehicleModel)}
                        >
                          Status
                        </div>
                      </div>
                    ) : value == "campaign_action" ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          height: "40px",
                        }}
                      >
                        <div
                          style={{
                            width: "60px",
                            height: "30px",
                            backgroundColor: primary,
                            fontSize: "12px",
                            fontFamily: "Rubik",
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "5px",
                            marginLeft: "2px",
                          }}
                          onClick={() => onClick("status", vehicleModel)}
                        >
                          Status
                        </div>
                      </div>
                    ) : key === "tenant_id" ? (
                      <p></p>
                    ) : (
                      <p style={{ margin: 0, textAlign: "left" }}>{value}</p>
                    )}
                  </div>
                ))}
              </div>
            ))}
          </>
        ) : (
          <div
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              fontSize: 14,
              fontWeight: 600,
              fontFamily: "Rubik",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            No Records Found
          </div>
        )}
      </div>
    </animated.div>
  );
}

export default CommonTable;
