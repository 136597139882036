import * as type from '../type';

export function getUsers(users) {
  return {
    type: type.GET_USERS,
    payload: users,
  }
}

export function setUserType(userType) {
  return {
    type: type.SET_USER_TYPE,
    payload: userType
  }
}

export function setTokens(tokens) {
  return {
    type: type.SET_TOKENS,
    payload: tokens
  }
}

export function setCred(cred){
  return{
    type: type.SET_CRED,
    payload: cred
  }
}

export function setWidth(width){
  return{
    type: type.SET_WIDTH,
    payload: width
  }
}