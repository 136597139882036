import React from 'react';
import './preview-model.css';

const PreviewModel = ({ data, onClose, onSubmit }) => {
  if (!data) return null;

  return (
    <div className="preview-modal">
      <div className="preview-modal-content">
        <span className="close-button" onClick={onClose}>&times;</span>
        <h2 className="modal-title">Preview</h2>
        
        <div className="preview-section">
          <h3 className="section-title">Access Policy</h3>
          <div className="section-content">
            <p><strong>Password Length:</strong> {data.passwordLength}</p>
            <p><strong>Password Expiration:</strong> {data.passwordExpiration}</p>
            <p><strong>Multi Factor Authentication:</strong> {data.multiFactorAuth ?"Required":"Optional"}</p>
            <p><strong>Remember User Device:</strong> {data.rememberUserDevice ? "Yes" : "No"}</p>
            <p><strong>Access To Web Client:</strong> {data.accessToWebClient ? "Yes" : "No"}</p>
            <p><strong>Password Strength:</strong> 
              {data.passCharacterNum ? " Numbers," : ""} 
              {data.passCharacterSp ? " Special Characters," : ""} 
              {data.passCharacterUp ? " Uppercase," : ""} 
              {data.passCharacterLo ? " Lowercase" : ""}
            </p>
          </div>
        </div>

        <div className="preview-section">
          <h3 className="section-title">Branding</h3>
          <div className="section-content">
            <p><strong>Domain Name:</strong> {data.domainName}</p>
            <p><strong>Brand Name:</strong> {data.brandName}</p>
            <p><strong>Mail Id:</strong> {data.mailId}</p>
            <p><strong>Description:</strong> {data.description}</p>
            <p><strong>Theme:</strong> {data.selectedTheme}</p>
            {data.logoFile && <img src={data.logoFile} alt="Brand Logo" className="preview-logo" />}
          </div>
        </div>

        <button className="submit-button" onClick={onSubmit}>Submit</button>
      </div>
    </div>
  );
};

export default PreviewModel;
